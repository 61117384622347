
export const BASE_URL = process.env.GATSBY_BASE_URL;

export const ApiUrls = {
  Basel: {
    BASEL_REGISTER: `${BASE_URL}/api/v1/auth/baselregister`,
    BOOTH_AI: `${BASE_URL}/api/v1/carousels/send_booth_ai_request`,
    BASEL_BOOTH_EMAIL: `${BASE_URL}/api/v1/carousels/send_booth_email`,
  },
  Carousels: {
    CREATE_TASK: `${BASE_URL}/api/v1/carousels/create_task`,
    PRINTFUL_TASK: `${BASE_URL}/api/v1/carousels/task_status`,
    GENERATE_ART: `${BASE_URL}/api/v1/carousels/generate`,
  },
}
