import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSavingDownloadUrl: false,
  isSavingDownloadUrlSuccess: false,
  isSavingDownloadUrlFailed: false,
  SaveDownloadUrlResponse: {},
  isDeletingDownloadUrl: false,
  isDeletingDownloadUrlSuccess: false,
  isDeletingDownloadUrlFailed: false,
  isFetchingSavedUrls: false,
  isFetchingSavedUrlsSuccess: false,
  isFetchingSavedUrlsFailed: false,
  SavedUrlsData: {},
};

const downloadsSlice = createSlice({
  name: 'downloads',
  initialState,
  reducers: {
    saveDownloadUrl(state, action) {
      state.isSavingDownloadUrl = true;
      state.isSavingDownloadUrlSuccess = false;
      state.isSavingDownloadUrlFailed = false;
    },
    saveDownloadUrlSuccess(state, action) {
      state.isSavingDownloadUrl = false;
      state.isSavingDownloadUrlSuccess = true;
      state.isSavingDownloadUrlFailed = false;
      state.SaveDownloadUrlResponse = action.payload;
    },
    saveDownloadUrlFailed(state, action) {
      state.isSavingDownloadUrl = false;
      state.isSavingDownloadUrlSuccess = false;
      state.isSavingDownloadUrlFailed = true;
    },
    deleteDownloadUrl(state, action) {
      state.isDeletingDownloadUrl = true;
      state.isDeletingDownloadUrlSuccess = false;
      state.isDeletingDownloadUrlFailed = false;
    },
    deleteDownloadUrlSuccess(state, action) {
      state.isDeletingDownloadUrl = false;
      state.isDeletingDownloadUrlSuccess = true;
      state.isDeletingDownloadUrlFailed = false;
    },
    deleteDownloadUrlFailed(state, action) {
      state.isDeletingDownloadUrl = false;
      state.isDeletingDownloadUrlSuccess = false;
      state.isDeletingDownloadUrlFailed = true;
    },
    getSavedUrls(state, action) {
      state.isFetchingSavedUrls = true;
      state.isFetchingSavedUrlsSuccess = false;
      state.isFetchingSavedUrlsFailed = false;
    },
    getSavedUrlsSuccess(state, action) {
      state.isFetchingSavedUrls = false;
      state.isFetchingSavedUrlsSuccess = true;
      state.isFetchingSavedUrlsFailed = false;
      state.SavedUrlsData = action.payload;
    },
    getSavedUrlsFailed(state, action) {
      state.isFetchingSavedUrls = false;
      state.isFetchingSavedUrlsSuccess = false;
      state.isFetchingSavedUrlsFailed = true;
    },
  }
})

export const {
  saveDownloadUrl,
  saveDownloadUrlSuccess,
  saveDownloadUrlFailed,
  deleteDownloadUrl,
  deleteDownloadUrlSuccess,
  deleteDownloadUrlFailed,
  getSavedUrls,
  getSavedUrlsSuccess,
  getSavedUrlsFailed,
} = downloadsSlice.actions;

export default downloadsSlice.reducer;

export const selectDownloadsState = (state) => state.downloadsState;
