
import { call, put, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import { getAuthorizationParam } from "../../services/apiCalls";
import {
  createPrintfulProduct,
  createPrintfulProductSuccess,
  createPrintfulProductFailed,
  getOrderEstimate,
  getOrderEstimateSuccess,
  getOrderEstimateFailed,
  placeOrder,
  placeOrderSuccess,
  placeOrderFailed,
} from "./checkoutSlice";

const BASE_URL = process.env.GATSBY_BASE_URL;

export function requestCreatePrintfulProduct(payload) {
  return axios.post(`${BASE_URL}/api/v1/checkout/create-product-printful`, {data: payload}, getAuthorizationParam());
}

export function requestGetOrderEstimate(payload) {
  return axios.post(`${BASE_URL}/api/v1/checkout/get-order-estimate`, payload, getAuthorizationParam());
}

export function requestPlaceOrder(payload) {
  return axios.post(`${BASE_URL}/api/v1/checkout/place-order`, payload, getAuthorizationParam());
}

export function* handleCreatePrintfulProduct(action) {
  try {
    const response = yield call(requestCreatePrintfulProduct, action.payload);
    const { data } = response;

    yield put(createPrintfulProductSuccess(data));
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      createPrintfulProductFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleGetOrderEstimate(action) {
  try {
    const response = yield call(requestGetOrderEstimate, action.payload);
    const { data } = response;

    if (data.code === 200) {
      yield put(getOrderEstimateSuccess(data));
    } else {
      yield put(
        getOrderEstimateFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      getOrderEstimateFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handlePlaceOrder(action) {
  try {
    const response = yield call(requestPlaceOrder, action.payload);
    const { data } = response;

    if (data.status === "success") {
      yield put(placeOrderSuccess(data.data));
    } else {
      yield put(
        placeOrderFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }
    
    yield put(
      placeOrderFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* checkoutSaga() {
  yield takeLatest(createPrintfulProduct.type, handleCreatePrintfulProduct);
  yield takeLatest(getOrderEstimate.type, handleGetOrderEstimate);
  yield takeLatest(placeOrder.type, handlePlaceOrder);
}
