import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isUpdatingPaymentIntent: false,
  isUpdatingPaymentIntentSuccess: false,
  isUpdatingPaymentIntentFailed: false,
  isPostingLedgerHistory: false,
  isPostingLedgerHistorySuccess: false,
  isPostingLedgerHistoryFailed: false,
  PostLedgerHistoryResponse: {},
  isFetchingLedgerHistory: false,
  isFetchingLedgerHistorySuccess: false,
  isFetchingLedgerHistoryFailed: false,
  LedgerHistoryData: {},
  isGettingLedgerDetails: false,
  isGettingLedgerDetailsSuccess: false,
  isGettingLedgerDetailsFailed: false,
  LedgerDetailsData: {},
  isFetchingCreditHistory: false,
  isFetchingCreditHistorySuccess: false,
  isFetchingCreditHistoryFailed: false,
  CreditHistoryData: {},
  isGettingCreditDetails: false,
  isGettingCreditDetailsSuccess: false,
  isGettingCreditDetailsFailed: false,
  CreditDetailsData: {},
};

const creditsSlice = createSlice({
  name: 'credits',
  initialState,
  reducers: {
    updatePaymentIntent(state, action) {
      state.isUpdatingPaymentIntent = true;
      state.isUpdatingPaymentIntentSuccess = false;
      state.isUpdatingPaymentIntentFailed = false;
    },
    updatePaymentIntentSuccess(state, action) {
      state.isUpdatingPaymentIntent = false;
      state.isUpdatingPaymentIntentSuccess = true;
      state.isUpdatingPaymentIntentFailed = false;
    },
    updatePaymentIntentFailed(state, action) {
      state.isUpdatingPaymentIntent = false;
      state.isUpdatingPaymentIntentSuccess = false;
      state.isUpdatingPaymentIntentFailed = true;
    },
    postLedgerHistory(state, action) {
      state.isPostingLedgerHistory = true;
      state.isPostingLedgerHistorySuccess = false;
      state.isPostingLedgerHistoryFailed = false;
    },
    postLedgerHistorySuccess(state, action) {
      state.isPostingLedgerHistory = false;
      state.isPostingLedgerHistorySuccess = true;
      state.isPostingLedgerHistoryFailed = false;
      state.PostLedgerHistoryResponse = action.payload;
    },
    postLedgerHistoryFailed(state, action) {
      state.isPostingLedgerHistory = false;
      state.isPostingLedgerHistorySuccess = false;
      state.isPostingLedgerHistoryFailed = true;
    },
    getLedgerHistory(state, action) {
      state.isFetchingLedgerHistory = true;
      state.isFetchingLedgerHistorySuccess = false;
      state.isFetchingLedgerHistoryFailed = false;
    },
    getLedgerHistorySuccess(state, action) {
      state.isFetchingLedgerHistory = false;
      state.isFetchingLedgerHistorySuccess = true;
      state.isFetchingLedgerHistoryFailed = false;
      state.LedgerHistoryData = action.payload;
    },
    getLedgerHistoryFailed(state, action) {
      state.isFetchingLedgerHistory = false;
      state.isFetchingLedgerHistorySuccess = false;
      state.isFetchingLedgerHistoryFailed = true;
    },
    getLedgerDetails(state, action) {
      state.isGettingLedgerDetails = true;
      state.isGettingLedgerDetailsSuccess = false;
      state.isGettingLedgerDetailsFailed = false;
    },
    getLedgerDetailsSuccess(state, action) {
      state.isGettingLedgerDetails = false;
      state.isGettingLedgerDetailsSuccess = true;
      state.isGettingLedgerDetailsFailed = false;
      state.LedgerDetailsData = action.payload;
    },
    getLedgerDetailsFailed(state, action) {
      state.isGettingLedgerDetails = false;
      state.isGettingLedgerDetailsSuccess = false;
      state.isGettingLedgerDetailsFailed = true;
    },
    getCreditHistory(state, action) {
      state.isFetchingCreditHistory = true;
      state.isFetchingCreditHistorySuccess = false;
      state.isFetchingCreditHistoryFailed = false;
    },
    getCreditHistorySuccess(state, action) {
      state.isFetchingCreditHistory = false;
      state.isFetchingCreditHistorySuccess = true;
      state.isFetchingCreditHistoryFailed = false;
      state.CreditHistoryData = action.payload;
    },
    getCreditHistoryFailed(state, action) {
      state.isFetchingCreditHistory = false;
      state.isFetchingCreditHistorySuccess = false;
      state.isFetchingCreditHistoryFailed = true;
    },
    getCreditDetails(state, action) {
      state.isGettingCreditDetails = true;
      state.isGettingCreditDetailsSuccess = false;
      state.isGettingCreditDetailsFailed = false;
    },
    getCreditDetailsSuccess(state, action) {
      state.isGettingCreditDetails = false;
      state.isGettingCreditDetailsSuccess = true;
      state.isGettingCreditDetailsFailed = false;
      state.CreditDetailsData = action.payload;
    },
    getCreditDetailsFailed(state, action) {
      state.isGettingCreditDetails = false;
      state.isGettingCreditDetailsSuccess = false;
      state.isGettingCreditDetailsFailed = true;
    },
  }
})

export const {
  updatePaymentIntent,
  updatePaymentIntentSuccess,
  updatePaymentIntentFailed,
  postLedgerHistory,
  postLedgerHistorySuccess,
  postLedgerHistoryFailed,
  getLedgerHistory,
  getLedgerHistorySuccess,
  getLedgerHistoryFailed,
  getLedgerDetails,
  getLedgerDetailsSuccess,
  getLedgerDetailsFailed,
  getCreditHistory,
  getCreditHistorySuccess,
  getCreditHistoryFailed,
  getCreditDetails,
  getCreditDetailsSuccess,
  getCreditDetailsFailed,
} = creditsSlice.actions;

export default creditsSlice.reducer;

export const selectCreditsState = (state) => state.creditsState;
