
import { call, put, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import { getAuthorizationParam } from "../../services/apiCalls";
import {
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFailed,
  getUsers,
  getUsersSuccess,
  getUsersFailed,
  logAsUser,
  logAsUserSuccess,
  logAsUserFailed,
  getUserProfile,
  getUserProfileSuccess,
  getUserProfileFailed,
  editUserProfile,
  editUserProfileSuccess,
  editUserProfileFailed,
  updateUserEmail,
  updateUserEmailSuccess,
  updateUserEmailFailed,
} from "./authSlice";

const BASE_URL = process.env.GATSBY_BASE_URL;

export function requestResetPassword(data) {
  return axios.post(`${BASE_URL}/api/v1/auth/confirmpasswordreset`, data);
}

export function requestGetUsers(perPage, page, searchTerm) {
  return axios
    .get(`${BASE_URL}/api/v1/auth/users`, {
      params: {
        perPage,
        page,
        searchTerm,
      },
      headers: getAuthorizationParam().headers,
    });
}

export function requestLogAsUser(data) {
  return axios.post(`${BASE_URL}/api/v1/auth/logAs`, data, getAuthorizationParam());
}

export function requestGetUserProfile(data) {
  return axios.post(`${BASE_URL}/api/v1/auth/get_profile`, data, getAuthorizationParam());
}

export function requestEditUserProfile(data) {
  return axios.post(`${BASE_URL}/api/v1/auth/edit_profile`, data, getAuthorizationParam());
}

export function requestUpdateUserEmail(data) {
  return axios.post(`${BASE_URL}/api/v1/auth/update_email`, data, getAuthorizationParam());
}

export function* handleResetPassword(action) {
  try {
    const response = yield call(requestResetPassword, action.payload);
    const { data } = response;

    if (data.status) {
      yield put(resetPasswordSuccess(data));
    } else {
      yield put(
        resetPasswordFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    yield put(
      resetPasswordFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleGetUsers(action) {
  try {
    const response = yield call(requestGetUsers, action.payload.PerPage, action.payload.Page, action.payload.SearchTerm);
    const { data } = response;

    if (data.code === 200) {
      yield put(getUsersSuccess(data));
    } else {
      yield put(
        getUsersFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      getUsersFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleLogAsUser(action) {
  try {
    const response = yield call(requestLogAsUser, action.payload);
    const { data } = response;

    if (data.status) {
      yield put(logAsUserSuccess(data));
    } else {
      yield put(
        logAsUserFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }
    
    yield put(
      logAsUserFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleGetUserProfile(action) {
  try {
    const response = yield call(requestGetUserProfile, action.payload);
    const { data } = response;

    if (data.data.status === "success") {
      yield put(getUserProfileSuccess(data.data));
    } else {
      yield put(
        getUserProfileFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }
    
    yield put(
      getUserProfileFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleEditUserProfile(action) {
  try {
    const response = yield call(requestEditUserProfile, action.payload);
    const { data } = response;

    if (data.data.status === "success") {
      yield put(editUserProfileSuccess(data.data));
    } else {
      yield put(
        editUserProfileFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      editUserProfileFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleUpdateUserEmail(action) {
  try {
    const response = yield call(requestUpdateUserEmail, action.payload);
    const { data } = response;

    if (data.data.status === "success") {
      yield put(updateUserEmailSuccess(data.data));
    } else {
      yield put(
        updateUserEmailFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      updateUserEmailFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* authSaga() {
  yield takeLatest(resetPassword.type, handleResetPassword);
  yield takeLatest(getUsers.type, handleGetUsers);
  yield takeLatest(logAsUser.type, handleLogAsUser);
  yield takeLatest(getUserProfile.type, handleGetUserProfile);
  yield takeLatest(editUserProfile.type, handleEditUserProfile);
  yield takeLatest(updateUserEmail.type, handleUpdateUserEmail);
}
