
import { call, put, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import { getAuthorizationParam } from "../../services/apiCalls";
import {
  getOrderHistory,
  getOrderHistorySuccess,
  getOrderHistoryFailed,
  getOrderDetail,
  getOrderDetailSuccess,
  getOrderDetailFailed,
} from "./ordersSlice";

const BASE_URL = process.env.GATSBY_BASE_URL;

export function requestGetOrderHistory(perPage, page) {
  return axios
    .get(`${BASE_URL}/api/v1/order/history`, {
      params: {
        perPage,
        page,
      },
      headers: getAuthorizationParam().headers,
    });
}

export function requestGetOrderDetail(id) {
  return axios
    .get(`${BASE_URL}/api/v1/order/${id}`, {
      params: {
        id,
      },
      headers: getAuthorizationParam().headers,
    });
}

export function* handleGetOrderHistory(action) {
  try {
    const response = yield call(requestGetOrderHistory, action.payload.PerPage, action.payload.Page);
    const { data } = response;

    if (data.code === 200) {
      yield put(getOrderHistorySuccess(data));
    } else {
      yield put(
        getOrderHistoryFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      getOrderHistoryFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleGetOrderDetail(action) {
  try {
    const response = yield call(requestGetOrderDetail, action.payload.orderId);
    const { data } = response;

    if (data.code === 200) {
      yield put(getOrderDetailSuccess(data.data));
    } else {
      yield put(
        getOrderDetailFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }
    
    yield put(
      getOrderDetailFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* ordersSaga() {
  yield takeLatest(getOrderHistory.type, handleGetOrderHistory);
  yield takeLatest(getOrderDetail.type, handleGetOrderDetail);
}
