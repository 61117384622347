
import { call, put, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import { getAuthorizationParam } from "../../services/apiCalls";
import {
  saveDownloadUrl,
  saveDownloadUrlSuccess,
  saveDownloadUrlFailed,
  deleteDownloadUrl,
  deleteDownloadUrlSuccess,
  deleteDownloadUrlFailed,
  getSavedUrls,
  getSavedUrlsSuccess,
  getSavedUrlsFailed,
} from "./downloadsSlice";

const BASE_URL = process.env.GATSBY_BASE_URL;

export function requestSaveDownloadUrl(payload) {
  return axios.post(`${BASE_URL}/api/v1/downloads/save`, payload, getAuthorizationParam());
}

export function requestDeleteDownloadUrl(payload) {
  return axios.delete(`${BASE_URL}/api/v1/downloads/delete_download_url`, {
    headers: getAuthorizationParam().headers,
    data: payload,
  });
}

export function requestGetSavedUrls(payload) {
  return axios.post(`${BASE_URL}/api/v1/downloads/get_savedUrls`, payload, getAuthorizationParam());
}

export function* handleSaveDownloadUrl(action) {
  try {
    const response = yield call(requestSaveDownloadUrl, action.payload);
    const { data } = response;

    yield put(saveDownloadUrlSuccess(data));
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      saveDownloadUrlFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleDeleteDownloadUrl(action) {
  try {
    const response = yield call(requestDeleteDownloadUrl, action.payload);
    const { data } = response;

    yield put(deleteDownloadUrlSuccess(data));
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      deleteDownloadUrlFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleGetSavedUrls(action) {
  try {
    const response = yield call(requestGetSavedUrls, action.payload);
    const { data } = response;

    if (data.status === "success") {
      yield put(getSavedUrlsSuccess(data.result));
    } else {
      yield put(
        getSavedUrlsFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }
    
    yield put(
      getSavedUrlsFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* downloadsSaga() {
  yield takeLatest(saveDownloadUrl.type, handleSaveDownloadUrl);
  yield takeLatest(deleteDownloadUrl.type, handleDeleteDownloadUrl);
  yield takeLatest(getSavedUrls.type, handleGetSavedUrls);
}
