import axios from "axios";
import { ApiUrls } from "./apiUrl";
import { getAccessToken } from "./auth";

/**
 * Get Authorization Param
 * @returns HTTP request header params
 */
export const getAuthorizationParam = () => {
  const access_token = getAccessToken();

  return {
    headers: {
      'Authorization': `Bearer ${access_token}`,
    },
  };
}

export const getArts = (prompt, count) => {
  return axios
    .get(ApiUrls.Carousels.GENERATE_ART, {
      params: {
        prompt,
        count,
      },
      ...getAuthorizationParam(),
    });
};
export const createTask = ( data ) => {
  return axios
    .post(ApiUrls.Carousels.CREATE_TASK, data, getAuthorizationParam())
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getPrintfulTask = (task_key,id) => {
  return axios
    .get(ApiUrls.Carousels.PRINTFUL_TASK, {
      params: {
        task_key,
        id,
      },
      ...getAuthorizationParam(),
    })
    .then(({ data }) => data)
    .catch((response) => response);
};
export const createTaskWithoutToken = ( data ) => {
  return axios
    .post(ApiUrls.Carousels.CREATE_TASK, data)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getPrintfulTaskWithoutToken = (task_key,id) => {
  return axios
    .get(ApiUrls.Carousels.PRINTFUL_TASK, {
      params: {
        task_key,
        id,
      },
    })
    .then(({ data }) => data)
    .catch((response) => response);
};

export const sendBaselUserEmail = ( data ) => {
  return axios
    .post(ApiUrls.Basel.BASEL_REGISTER, data)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const sendBaselBoothEmail = ( data ) => {
  return axios
    .post(ApiUrls.Basel.BASEL_BOOTH_EMAIL, data)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const sendBoothAIRequest = ( data ) => {
  return axios.post(ApiUrls.Basel.BOOTH_AI, data);
};
