const firebaseConfig = {
  apiKey: "AIzaSyDHafqPdhH3qY6TK4ID3grD1wtmJD3FMk0",
  authDomain: "austinproject-dceff.firebaseapp.com",
  projectId: "austinproject-dceff",
  storageBucket: "austinproject-dceff.appspot.com",
  messagingSenderId: "385787193877",
  appId: "1:385787193877:web:392d81a86ccb1867053c1c",
  measurementId: "G-ERXEHF2XDW",
};

export default firebaseConfig;

// export const firebaseDiscordConfig = {
//   apiKey: "AIzaSyBYUUdReDsUZTe_o8es730KciByWVnGieY",
//   authDomain: "austin-s-project-for.firebaseapp.com",
//   databaseURL: "MY_FIREBASE_DB_URL",
//   projectId: "austin-s-project-for",
//   storageBucket: "austin-s-project-for.appspot.com",
//   messagingSenderId: "606038992232",
//   appId: "1:606038992232:web:c3721ea2917db480dd22dd",
//   measurementId: "G-HNY4C4M67S"
// };

// export const oauthKeys = {
//   clientID: "1034185010342793338",
//   clientSecret: "WJGzKn_T9zekxFJqWID88rbXiy7Io1Ql",
//   scopes: ["identify", "email", "guilds.join", "guilds"]
// }
