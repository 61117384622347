
import { call, put, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
  postImage,
  postImageSuccess,
  postImageFailed,
} from "./imageSlice";

export const GATSBY_POST_IMAGE_API_URL = process.env.GATSBY_POST_IMAGE_API_URL;
const XImageApiKey = process.env.GATSBY_X_IMAGE_API_KEY;

export function requestPostImage(base64Url) {
  return axios
    .post(`${GATSBY_POST_IMAGE_API_URL}/api/v1/postBaseImage`, {
      base64Url
    }, {
      headers: {
        'x-image-api-key': XImageApiKey,
      }
    });
}

export function* handlePostImage(action) {
  try {
    const response = yield call(requestPostImage, action.payload.base64Url);
    const { data } = response;

    if (data.status === "success") {
      yield put(postImageSuccess(data));
    } else {
      yield put(
        postImageFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    yield put(
      postImageFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* imageSaga() {
  yield takeLatest(postImage.type, handlePostImage);
}
