import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCreatingPrintfulProduct: false,
  isCreatingPrintfulProductSuccess: false,
  isCreatingPrintfulProductFailed: false,
  CreatePrintfulProductData: [],
  isGettingOrderEstimate: false,
  isGettingOrderEstimateSuccess: false,
  isGettingOrderEstimateFailed: false,
  GetOrderEstimateResponse: {},
  isPlacingOrder: false,
  isPlacingOrderSuccess: false,
  isPlacingOrderFailed: false,
  PlaceOrderDto: {},
  SelectedRefreshProductKey: '',
  SelectedProductKeys: [],
  ProductDetailsModalProps: {
    open: false,
    product: null,
    productKey: null,
    productLabel: null,
    dataId: null,
  },
};

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    createPrintfulProduct(state, action) {
      state.isCreatingPrintfulProduct = true;
      state.isCreatingPrintfulProductSuccess = false;
      state.isCreatingPrintfulProductFailed = false;
    },
    createPrintfulProductSuccess(state, action) {
      state.isCreatingPrintfulProduct = false;
      state.isCreatingPrintfulProductSuccess = true;
      state.isCreatingPrintfulProductFailed = false;
      state.CreatePrintfulProductData = action.payload;
    },
    createPrintfulProductFailed(state, action) {
      state.isCreatingPrintfulProduct = false;
      state.isCreatingPrintfulProductSuccess = false;
      state.isCreatingPrintfulProductFailed = true;
    },
    getOrderEstimate(state, action) {
      state.isGettingOrderEstimate = true;
      state.isGettingOrderEstimateSuccess = false;
      state.isGettingOrderEstimateFailed = false;
    },
    getOrderEstimateSuccess(state, action) {
      state.isGettingOrderEstimate = false;
      state.isGettingOrderEstimateSuccess = true;
      state.isGettingOrderEstimateFailed = false;
      state.GetOrderEstimateResponse = action.payload;
    },
    getOrderEstimateFailed(state, action) {
      state.isGettingOrderEstimate = false;
      state.isGettingOrderEstimateSuccess = false;
      state.isGettingOrderEstimateFailed = true;
    },
    placeOrder(state, action) {
      state.isPlacingOrder = true;
      state.isPlacingOrderSuccess = false;
      state.isPlacingOrderFailed = false;
    },
    placeOrderSuccess(state, action) {
      state.isPlacingOrder = false;
      state.isPlacingOrderSuccess = true;
      state.isPlacingOrderFailed = false;
      state.PlaceOrderDto = action.payload;
    },
    placeOrderFailed(state, action) {
      state.isPlacingOrder = false;
      state.isPlacingOrderSuccess = false;
      state.isPlacingOrderFailed = true;
    },
    setSelectedRefreshProductKey(state, action) {//deprecated
      state.SelectedRefreshProductKey = action.payload.productKey;
    },
    setSelectedProductKeys(state, action) {
      state.SelectedProductKeys = action.payload.SelectedProductKeys;
    },
    setShowProductDetailsModal(state, action) {
      state.ProductDetailsModalProps = action.payload.ProductDetailsModalProps;
    },
  }
})

export const {
  createPrintfulProduct,
  createPrintfulProductSuccess,
  createPrintfulProductFailed,
  getOrderEstimate,
  getOrderEstimateSuccess,
  getOrderEstimateFailed,
  placeOrder,
  placeOrderSuccess,
  placeOrderFailed,
  setSelectedRefreshProductKey,//deprecated
  setSelectedProductKeys,
  setShowProductDetailsModal,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;

export const selectCheckoutState = (state) => state.checkoutState;
