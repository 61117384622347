
import { call, put, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import { getAuthorizationParam } from "../../services/apiCalls";
import {
  createPaymentIntent,
  createPaymentIntentSuccess,
  createPaymentIntentFailed,
} from "./paymentSlice";

const BASE_URL = process.env.GATSBY_BASE_URL;

export function requestCreatePaymentIntent(payload) {
  return axios.post(`${BASE_URL}/api/v1/payment/create-payment-intent`, payload, getAuthorizationParam());
}

export function* handleCreatePaymentIntent(action) {
  try {
    const response = yield call(requestCreatePaymentIntent, action.payload);
    const { data } = response;

    if (data.status === "success") {
      yield put(createPaymentIntentSuccess(data));
    } else {
      yield put(
        createPaymentIntentFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }
    
    yield put(
      createPaymentIntentFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* paymentSaga() {
  yield takeLatest(createPaymentIntent.type, handleCreatePaymentIntent);
}
