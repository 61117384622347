
import { call, put, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import { getAuthorizationParam } from "../../services/apiCalls";
import {
  getUserStores,
  getUserStoresSuccess,
  getUserStoresFailed,
  getStoreDetail,
  getStoreDetailSuccess,
  getStoreDetailFailed,
  updateStore,
  updateStoreSuccess,
  updateStoreFailed,
  getStoreCollections,
  getStoreCollectionsSuccess,
  getStoreCollectionsFailed,
  createStoreCollection,
  createStoreCollectionSuccess,
  createStoreCollectionFailed,
  bulkDeleteStoreCollections,
  bulkDeleteStoreCollectionsSuccess,
  bulkDeleteStoreCollectionsFailed,
  bulkEditCollectionProductTitles,
  bulkEditCollectionProductTitlesSuccess,
  bulkEditCollectionProductTitlesFailed,
  bulkEditCollectionProductPrices,
  bulkEditCollectionProductPricesSuccess,
  bulkEditCollectionProductPricesFailed,
  getStoreCollectionsWithProducts,
  getStoreCollectionsWithProductsSuccess,
  getStoreCollectionsWithProductsFailed,
  getStoreCollectionProducts,
  getStoreCollectionProductsSuccess,
  getStoreCollectionProductsFailed,
  updateStoreCollection,
  updateStoreCollectionSuccess,
  updateStoreCollectionFailed,
  updateStoreCollectionImage,
  updateStoreCollectionImageSuccess,
  updateStoreCollectionImageFailed,
  bulkUpdateStoreProductsStatus,
  bulkUpdateStoreProductsStatusSuccess,
  bulkUpdateStoreProductsStatusFailed,
  bulkUpdateStoreProductsPrice,
  bulkUpdateStoreProductsPriceSuccess,
  bulkUpdateStoreProductsPriceFailed,
  bulkDeleteStoreProducts,
  bulkDeleteStoreProductsSuccess,
  bulkDeleteStoreProductsFailed,
  getStoreProductDetails,
  getStoreProductDetailsSuccess,
  getStoreProductDetailsFailed,
  updateStoreProductDetails,
  updateStoreProductDetailsSuccess,
  updateStoreProductDetailsFailed,
  updateStoreProductImage,
  updateStoreProductImageSuccess,
  updateStoreProductImageFailed,
  undoStoreProductImage,
  undoStoreProductImageSuccess,
  undoStoreProductImageFailed,
  postStoreProducts,
  postStoreProductsSuccess,
  postStoreProductsFailed,
} from "./storesSlice";

const BASE_URL = process.env.GATSBY_BASE_URL;

export function requestGetUserStores() {
  return axios.get(`${BASE_URL}/api/v1/stores/myStores`, getAuthorizationParam());
}

export function requestGetStoreDetail(storeId) {
  return axios.get(`${BASE_URL}/api/v1/stores/${storeId}`, getAuthorizationParam());
}

export function requestUpdateStore(data) {
  return axios.put(`${BASE_URL}/api/v1/stores/${data.storeId}`, data.payload, getAuthorizationParam());
}

export function requestGetStoreCollections(storeId) {
  return axios.get(`${BASE_URL}/api/v1/stores/${storeId}/collections`, getAuthorizationParam());
}

export function requestCreateStoreCollection(data) {
  return axios.post(`${BASE_URL}/api/v1/stores/${data.storeId}/collections`, data.collection, getAuthorizationParam());
}

export function requestBulkDeleteStoreCollections(data) {
  return axios.delete(`${BASE_URL}/api/v1/stores/${data.storeId}/collections`, {
    headers: getAuthorizationParam().headers,
    data: {
      collections: data.collections
    },
  });
}

export function requestBulkEditCollectionProductTitles(data) {
  return axios.put(`${BASE_URL}/api/v1/stores/${data.storeId}/collections/products/title`, 
    {
      collections: data.collections,
      titlePrefix: data.titlePrefix,
    },
    getAuthorizationParam(),
  );
}

export function requestBulkEditCollectionProductPrices(data) {
  return axios.put(`${BASE_URL}/api/v1/stores/${data.storeId}/collections/products/price`, 
    {
      collections: data.collections,
      BulkEditOption: data.bulkEditOption,
    },
    getAuthorizationParam(),
  );
}

export function requestGetStoreCollectionsWithProducts(storeId) {
  return axios.get(`${BASE_URL}/api/v1/stores/${storeId}/collectionsWithProducts`, getAuthorizationParam());
}

export function requestGetStoreCollectionProducts(storeId, tagId, RangeSize) {
  return axios
    .get(`${BASE_URL}/api/v1/stores/${storeId}/collections/${tagId}/products`, {
      params: {
        RangeSize,
      },
      headers: getAuthorizationParam().headers,
    });
}

export function requestUpdateStoreCollection(storeId, collectionId, data) {
  return axios.put(`${BASE_URL}/api/v1/stores/${storeId}/collections/${collectionId}`, data, getAuthorizationParam());
}

export function requestUpdateStoreCollectionImage(storeId, collectionId, attachment) {
  return axios.put(`${BASE_URL}/api/v1/stores/${storeId}/collections/${collectionId}/image`, { attachment }, getAuthorizationParam());
}

export function requestbulkUpdateStoreProductsStatus(data) {
  return axios.put(`${BASE_URL}/api/v1/stores/${data.storeId}/products/status`, {
    ProductIds: data.productIds,
    Action: data.action,
  }, getAuthorizationParam());
}

export function requestbulkUpdateStoreProductsPrice(data) {
  return axios.put(`${BASE_URL}/api/v1/stores/${data.storeId}/products/price`, {
    ProductIds: data.productIds,
    BulkEditOption: data.bulkEditOption,
  }, getAuthorizationParam());
}

export function requestBulkDeleteStoreProducts(data) {
  return axios.delete(`${BASE_URL}/api/v1/stores/${data.storeId}/products`, {
    headers: getAuthorizationParam().headers,
    data: {
      ProductIds: data.productIds,
      collectionId: data.collectionId,
      collectionTagId: data.collectionTagId,
    }
  });
}

export function requestGetStoreProductDetails(storeId, productId) {
  return axios.get(`${BASE_URL}/api/v1/stores/${storeId}/products/${productId}`, getAuthorizationParam());
}

export function requestUpdateStoreProductDetails(storeId, productId, payload) {
  return axios.put(`${BASE_URL}/api/v1/stores/${storeId}/products/${productId}`, payload, getAuthorizationParam());
}

export function requestUpdateStoreProductImage(storeId, productId, imageId, payload) {
  return axios.put(`${BASE_URL}/api/v1/stores/${storeId}/products/${productId}/images/${imageId}`, { payload }, getAuthorizationParam());
}

export function requestUndoStoreProductImage(storeId, productId, imageId) {
  return axios.post(`${BASE_URL}/api/v1/stores/${storeId}/products/${productId}/images/${imageId}/undo`, {}, getAuthorizationParam());
}

export function requestPostStoreProducts(payload) {
  return axios.post(`${BASE_URL}/api/v1/integrations/createShopifyProduct`, payload, getAuthorizationParam());
}

export function* handleGetUserStores() {
  try {
    const response = yield call(requestGetUserStores);
    const { data } = response;

    if (data.code === 200) {
      yield put(getUserStoresSuccess(data.data));
    } else {
      yield put(
        getUserStoresFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      getUserStoresFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleGetStoreDetail(action) {
  try {
    const response = yield call(requestGetStoreDetail, action.payload.storeId);
    const { data } = response;

    if (data.code === 200) {
      yield put(getStoreDetailSuccess(data.data));
    } else {
      yield put(
        getStoreDetailFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      getStoreDetailFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleUpdateStore(action) {
  try {
    const response = yield call(requestUpdateStore, action.payload);
    const { data } = response;

    if (data.code === 200) {
      yield put(updateStoreSuccess(data.data));
    } else {
      yield put(
        updateStoreFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      updateStoreFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleGetStoreCollections(action) {
  try {
    const response = yield call(requestGetStoreCollections, action.payload.storeId);
    const { data } = response;

    if (data.code === 200) {
      yield put(getStoreCollectionsSuccess(data.collections));
    } else {
      yield put(
        getStoreCollectionsFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      getStoreCollectionsFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleCreateStoreCollection(action) {
  try {
    const response = yield call(requestCreateStoreCollection, action.payload);
    const { data } = response;

    if (data.code === 201) {
      yield put(createStoreCollectionSuccess(data.data));
    } else {
      yield put(
        createStoreCollectionFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      createStoreCollectionFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleBulkDeleteStoreCollections(action) {
  try {
    const response = yield call(requestBulkDeleteStoreCollections, action.payload);
    const { data } = response;

    if (data.code === 200) {
      yield put(bulkDeleteStoreCollectionsSuccess(data));
    } else {
      yield put(
        bulkDeleteStoreCollectionsFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      bulkDeleteStoreCollectionsFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleBulkEditCollectionProductTitles(action) {
  try {
    const response = yield call(requestBulkEditCollectionProductTitles, action.payload);
    const { data } = response;

    if (data.code === 200) {
      yield put(bulkEditCollectionProductTitlesSuccess(data));
    } else {
      yield put(
        bulkEditCollectionProductTitlesFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      bulkEditCollectionProductTitlesFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleBulkEditCollectionProductPrices(action) {
  try {
    const response = yield call(requestBulkEditCollectionProductPrices, action.payload);
    const { data } = response;

    if (data.code === 200) {
      yield put(bulkEditCollectionProductPricesSuccess(data));
    } else {
      yield put(
        bulkEditCollectionProductPricesFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      bulkEditCollectionProductPricesFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleGetStoreCollectionsWithProducts(action) {
  try {
    const response = yield call(requestGetStoreCollectionsWithProducts, action.payload.storeId);
    const { data } = response;

    if (data.code === 200) {
      yield put(getStoreCollectionsWithProductsSuccess(data));
    } else {
      yield put(
        getStoreCollectionsWithProductsFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      getStoreCollectionsWithProductsFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleGetStoreCollectionProducts(action) {
  try {
    const response = yield call(requestGetStoreCollectionProducts, action.payload.storeId, action.payload.tagId, action.payload.rangeSize);
    const { data } = response;

    if (data.code === 200) {
      yield put(getStoreCollectionProductsSuccess(data ?? {}));
    } else {
      yield put(
        getStoreCollectionProductsFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      getStoreCollectionProductsFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleUpdateStoreCollection(action) {
  try {
    const response = yield call(requestUpdateStoreCollection, action.payload.storeId, action.payload.collectionId, action.payload.data);
    const { data } = response;

    if (data.code === 200) {
      yield put(updateStoreCollectionSuccess(data.data));
    } else {
      yield put(
        updateStoreCollectionFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      updateStoreCollectionFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleUpdateStoreCollectionImage(action) {
  try {
    const response = yield call(requestUpdateStoreCollectionImage, action.payload.storeId, action.payload.collectionId, action.payload.attachment);
    const { data } = response;

    if (data.code === 200) {
      yield put(updateStoreCollectionImageSuccess(data.data));
    } else {
      yield put(
        updateStoreCollectionImageFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }
    
    yield put(
      updateStoreCollectionImageFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handlebulkUpdateStoreProductsStatus(action) {
  try {
    const response = yield call(requestbulkUpdateStoreProductsStatus, action.payload);
    const { data } = response;

    if (data.code === 200) {
      yield put(bulkUpdateStoreProductsStatusSuccess(data));
    } else {
      yield put(
        bulkUpdateStoreProductsStatusFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      bulkUpdateStoreProductsStatusFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handlebulkUpdateStoreProductsPrice(action) {
  try {
    const response = yield call(requestbulkUpdateStoreProductsPrice, action.payload);
    const { data } = response;

    if (data.code === 200) {
      yield put(bulkUpdateStoreProductsPriceSuccess(data));
    } else {
      yield put(
        bulkUpdateStoreProductsPriceFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      bulkUpdateStoreProductsPriceFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleBulkDeleteStoreProducts(action) {
  try {
    const response = yield call(requestBulkDeleteStoreProducts, action.payload);
    const { data } = response;

    if (data.code === 200) {
      yield put(bulkDeleteStoreProductsSuccess(data));
    } else {
      yield put(
        bulkDeleteStoreProductsFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      bulkDeleteStoreProductsFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleGetStoreProductDetails(action) {
  try {
    const response = yield call(requestGetStoreProductDetails, action.payload.storeId, action.payload.productId);
    const { data } = response;

    if (data.code === 200) {
      yield put(getStoreProductDetailsSuccess(data));
    } else {
      yield put(
        getStoreProductDetailsFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      getStoreProductDetailsFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleUpdateStoreProductDetails(action) {
  try {
    const response = yield call(requestUpdateStoreProductDetails, action.payload.storeId, action.payload.productId, action.payload.data);
    const { data } = response;

    if (data.code === 200) {
      yield put(updateStoreProductDetailsSuccess(data));
    } else {
      yield put(
        updateStoreProductDetailsFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      updateStoreProductDetailsFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleUpdateStoreProductImage(action) {
  try {
    const response = yield call(requestUpdateStoreProductImage, action.payload.storeId, action.payload.productId, action.payload.imageId, action.payload.data);
    const { data } = response;

    if (data.code === 200) {
      yield put(updateStoreProductImageSuccess(data));
    } else {
      yield put(
        updateStoreProductImageFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      updateStoreProductImageFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleUndoStoreProductImage(action) {
  try {
    const response = yield call(requestUndoStoreProductImage, action.payload.storeId, action.payload.productId, action.payload.imageId);
    const { data } = response;

    if (data.code === 200) {
      yield put(undoStoreProductImageSuccess(data));
    } else {
      yield put(
        undoStoreProductImageFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      undoStoreProductImageFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handlePostStoreProducts(action) {
  try {
    const response = yield call(requestPostStoreProducts, action.payload);
    const { data } = response;

    if (data.code === 200) {
      yield put(postStoreProductsSuccess(data.data));
    } else {
      yield put(
        postStoreProductsFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }
    
    yield put(
      postStoreProductsFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* storesSaga() {
  yield takeLatest(getUserStores.type, handleGetUserStores);
  yield takeLatest(getStoreDetail.type, handleGetStoreDetail);
  yield takeLatest(updateStore.type, handleUpdateStore);
  yield takeLatest(getStoreCollections.type, handleGetStoreCollections);
  yield takeLatest(createStoreCollection.type, handleCreateStoreCollection);
  yield takeLatest(bulkDeleteStoreCollections.type, handleBulkDeleteStoreCollections);
  yield takeLatest(bulkEditCollectionProductTitles.type, handleBulkEditCollectionProductTitles);
  yield takeLatest(bulkEditCollectionProductPrices.type, handleBulkEditCollectionProductPrices);
  yield takeLatest(getStoreCollectionsWithProducts.type, handleGetStoreCollectionsWithProducts);
  yield takeLatest(getStoreCollectionProducts.type, handleGetStoreCollectionProducts);
  yield takeLatest(updateStoreCollection.type, handleUpdateStoreCollection);
  yield takeLatest(updateStoreCollectionImage.type, handleUpdateStoreCollectionImage);
  yield takeLatest(bulkUpdateStoreProductsStatus.type, handlebulkUpdateStoreProductsStatus);
  yield takeLatest(bulkUpdateStoreProductsPrice.type, handlebulkUpdateStoreProductsPrice);
  yield takeLatest(bulkDeleteStoreProducts.type, handleBulkDeleteStoreProducts);
  yield takeLatest(getStoreProductDetails.type, handleGetStoreProductDetails);
  yield takeLatest(updateStoreProductDetails.type, handleUpdateStoreProductDetails);
  yield takeLatest(updateStoreProductImage.type, handleUpdateStoreProductImage);
  yield takeLatest(undoStoreProductImage.type, handleUndoStoreProductImage);
  yield takeLatest(postStoreProducts.type, handlePostStoreProducts);
}
