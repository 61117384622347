import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCreatingPaymentIntent: false,
  isCreatingPaymentIntentSuccess: false,
  isCreatingPaymentIntentFailed: false,
  CreatePaymentIntentResponse: {},
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    createPaymentIntent(state, action) {
      state.isCreatingPaymentIntent = true;
      state.isCreatingPaymentIntentSuccess = false;
      state.isCreatingPaymentIntentFailed = false;
    },
    createPaymentIntentSuccess(state, action) {
      state.isCreatingPaymentIntent = false;
      state.isCreatingPaymentIntentSuccess = true;
      state.isCreatingPaymentIntentFailed = false;
      state.CreatePaymentIntentResponse = action.payload;
    },
    createPaymentIntentFailed(state, action) {
      state.isCreatingPaymentIntent = false;
      state.isCreatingPaymentIntentSuccess = false;
      state.isCreatingPaymentIntentFailed = true;
    },
  }
})

export const {
  createPaymentIntent,
  createPaymentIntentSuccess,
  createPaymentIntentFailed,
} = paymentSlice.actions;

export default paymentSlice.reducer;

export const selectPaymentState = (state) => state.paymentState;
