import React, { createContext, useState, useContext, useMemo } from "react";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { store } from "./src/store";

export const ApplicationContext = createContext({
  applicationState: {},
  setApplicationState: () => {},
  creditCount: 0,
  setCreditCount: () => {},
  checkoutState: {},
  setCheckoutState: () => {},
});

export const useApplicationContext = () => useContext(ApplicationContext);

const ContextProvider = ({ children }) => {
  const [applicationState, setApplicationState] = useState({
    detailedDescription: "",
    accountstep: 1,
    resetpasswordstep: 1,
    signupEmail: "",
    email: "",
    job_id: "",
    carouselPageCount: 6,
    currentThumbnail: 1,
    totalCount:  0,
    mycreationsSelectedItemIndex: [],
    mycreationsSelectedCount: 0,
    user_role: "USER",
    homepageUploadTab: false,
  });

  const [creditCount, setCreditCount] = useState(0)

  useEffect(() => {
    const currentUser = typeof window !== "undefined" && JSON.parse(localStorage.getItem("currentUser") || "{}");
    const credits = currentUser.credits;
    setCreditCount(credits);
  }, [])

  //Checkout State
  const [checkoutState, setCheckoutState] = useState({
    newJourney: false,
    createPrintfulProductData: [],
    recipientAddress: {},
    flow: null,
  });

  const value = useMemo(
    () => ({ 
      applicationState, 
      setApplicationState, 
      creditCount, 
      setCreditCount,
      checkoutState,
      setCheckoutState,
    }), 
    [applicationState, creditCount, checkoutState]
  );

  return (
    <ApplicationContext.Provider
      value={value}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export default ({ element }) => (
  <Provider store={store}>
    <ContextProvider>
      {element}
    </ContextProvider>
  </Provider>
);
