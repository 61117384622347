import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isGeneratingArts: false,
  isGeneratingArtsSuccess: false,
  isGeneratingArtsFailed: false,
  ArtsDto: [],
  isUpscalingImage: false,
  isUpscalingImageSuccess: false,
  isUpscalingImageFailed: false,
  UpscaleImageResponseDto: {},
  isGettingSavedCarousels: false,
  isGettingSavedCarouselsSuccess: false,
  isGettingSavedCarouselsFailed: false,
  SavedCarouselsDto: {},
  isDeletingCarousels: false,
  isDeletingCarouselsSuccess: false,
  isDeletingCarouselsFailed: false,
  isGettingSavedCarouselsToDisplay: false,
  isGettingSavedCarouselsToDisplaySuccess: false,
  isGettingSavedCarouselsToDisplayFailed: false,
  SavedCarouselsToDisplayDto: [],
  isSavingCarousel: false,
  isSavingCarouselSuccess: false,
  isSavingCarouselFailed: false,
  isUpdatingCarousel: false,
  isUpdatingCarouselSuccess: false,
  isUpdatingCarouselFailed: false,
};

const carouselsSlice = createSlice({
  name: 'carousels',
  initialState,
  reducers: {
    generateArts(state, action) {
      state.isGeneratingArts = true;
      state.isGeneratingArtsSuccess = false;
      state.isGeneratingArtsFailed = false;
    },
    generateArtsSuccess(state, action) {
      state.isGeneratingArts = false;
      state.isGeneratingArtsSuccess = true;
      state.isGeneratingArtsFailed = false;
      state.ArtsDto = action.payload;
    },
    generateArtsFailed(state, action) {
      state.isGeneratingArts = false;
      state.isGeneratingArtsSuccess = false;
      state.isGeneratingArtsFailed = true;
    },
    upscaleImage(state, action) {
      state.isUpscalingImage = true;
      state.isUpscalingImageSuccess = false;
      state.isUpscalingImageFailed = false;
    },
    upscaleImageSuccess(state, action) {
      state.isUpscalingImage = false;
      state.isUpscalingImageSuccess = true;
      state.isUpscalingImageFailed = false;
      state.UpscaleImageResponseDto = action.payload;
    },
    upscaleImageFailed(state, action) {
      state.isUpscalingImage = false;
      state.isUpscalingImageSuccess = false;
      state.isUpscalingImageFailed = true;
    },
    resetUpscaleImage(state, action) {
      state.isUpscalingImage = false;
      state.isUpscalingImageSuccess = false;
      state.isUpscalingImageFailed = false;
    },
    getSavedCarousels(state, action) {
      state.isGettingSavedCarousels = true;
      state.isGettingSavedCarouselsSuccess = false;
      state.isGettingSavedCarouselsFailed = false;
    },
    getSavedCarouselsSuccess(state, action) {
      state.isGettingSavedCarousels = false;
      state.isGettingSavedCarouselsSuccess = true;
      state.isGettingSavedCarouselsFailed = false;
      state.SavedCarouselsDto = action.payload;
    },
    getSavedCarouselsFailed(state, action) {
      state.isGettingSavedCarousels = false;
      state.isGettingSavedCarouselsSuccess = false;
      state.isGettingSavedCarouselsFailed = true;
    },
    deleteCarousels(state, action) {
      state.isDeletingCarousels = true;
      state.isDeletingCarouselsSuccess = false;
      state.isDeletingCarouselsFailed = false;
    },
    deleteCarouselsSuccess(state, action) {
      state.isDeletingCarousels = false;
      state.isDeletingCarouselsSuccess = true;
      state.isDeletingCarouselsFailed = false;
    },
    deleteCarouselsFailed(state, action) {
      state.isDeletingCarousels = false;
      state.isDeletingCarouselsSuccess = false;
      state.isDeletingCarouselsFailed = true;
    },
    getSavedCarouselsToDisplay(state, action) {
      state.isGettingSavedCarouselsToDisplay = true;
      state.isGettingSavedCarouselsToDisplaySuccess = false;
      state.isGettingSavedCarouselsToDisplayFailed = false;
    },
    getSavedCarouselsToDisplaySuccess(state, action) {
      state.isGettingSavedCarouselsToDisplay = false;
      state.isGettingSavedCarouselsToDisplaySuccess = true;
      state.isGettingSavedCarouselsToDisplayFailed = false;
      state.SavedCarouselsToDisplayDto = action.payload;
    },
    getSavedCarouselsToDisplayFailed(state, action) {
      state.isGettingSavedCarouselsToDisplay = false;
      state.isGettingSavedCarouselsToDisplaySuccess = false;
      state.isGettingSavedCarouselsToDisplayFailed = true;
    },
    saveCarousel(state, action) {
      state.isSavingCarousel = true;
      state.isSavingCarouselSuccess = false;
      state.isSavingCarouselFailed = false;
    },
    saveCarouselSuccess(state, action) {
      state.isSavingCarousel = false;
      state.isSavingCarouselSuccess = true;
      state.isSavingCarouselFailed = false;
    },
    saveCarouselFailed(state, action) {
      state.isSavingCarousel = false;
      state.isSavingCarouselSuccess = false;
      state.isSavingCarouselFailed = true;
    },
    updateCarousel(state, action) {
      state.isUpdatingCarousel = true;
      state.isUpdatingCarouselSuccess = false;
      state.isUpdatingCarouselFailed = false;
    },
    updateCarouselSuccess(state, action) {
      state.isUpdatingCarousel = false;
      state.isUpdatingCarouselSuccess = true;
      state.isUpdatingCarouselFailed = false;
    },
    updateCarouselFailed(state, action) {
      state.isUpdatingCarousel = false;
      state.isUpdatingCarouselSuccess = false;
      state.isUpdatingCarouselFailed = true;
    },
  }
})

export const {
  generateArts,
  generateArtsSuccess,
  generateArtsFailed,
  upscaleImage,
  upscaleImageSuccess,
  upscaleImageFailed,
  resetUpscaleImage,
  getSavedCarousels,
  getSavedCarouselsSuccess,
  getSavedCarouselsFailed,
  deleteCarousels,
  deleteCarouselsSuccess,
  deleteCarouselsFailed,
  getSavedCarouselsToDisplay,
  getSavedCarouselsToDisplaySuccess,
  getSavedCarouselsToDisplayFailed,
  saveCarousel,
  saveCarouselSuccess,
  saveCarouselFailed,
  updateCarousel,
  updateCarouselSuccess,
  updateCarouselFailed,
} = carouselsSlice.actions;

export default carouselsSlice.reducer;

export const selectCarouselsState = (state) => state.carouselsState;
