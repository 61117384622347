import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isResettingPassword: false,
  isResettingPasswordSuccess: false,
  isResettingPasswordFailed: false,
  ResetPasswordResponse: {},
  isFetchingUsers: false,
  isFetchingUsersSuccess: false,
  isFetchingUsersFailed: false,
  UsersResponse: {},
  isLoggingAs: false,
  isLoggingAsSuccess: false,
  isLoggingAsFailed: false,
  LogAsResponse: {},
  isGettingUserProfile: false,
  isGettingUserProfileSuccess: false,
  isGettingUserProfileFailed: false,
  UserProfileResponse: {},
  isEditingUserProfile: false,
  isEditingUserProfileSuccess: false,
  isEditingUserProfileFailed: false,
  isUpdatingUserEmail: false,
  isUpdatingUserEmailSuccess: false,
  isUpdatingUserEmailFailed: false,
  UpdateUserEmailResponse: {},
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetPassword(state, action) {
      state.isResettingPassword = true;
      state.isResettingPasswordSuccess = false;
      state.isResettingPasswordFailed = false;
    },
    resetPasswordSuccess(state, action) {
      state.isResettingPassword = false;
      state.isResettingPasswordSuccess = true;
      state.isResettingPasswordFailed = false;
      state.ResetPasswordResponse = action.payload;
    },
    resetPasswordFailed(state, action) {
      state.isResettingPassword = false;
      state.isResettingPasswordSuccess = false;
      state.isResettingPasswordFailed = true;
    },
    getUsers(state, action) {
      state.isFetchingUsers = true;
      state.isFetchingUsersSuccess = false;
      state.isFetchingUsersFailed = false;
    },
    getUsersSuccess(state, action) {
      state.isFetchingUsers = false;
      state.isFetchingUsersSuccess = true;
      state.isFetchingUsersFailed = false;
      state.UsersResponse = action.payload;
    },
    getUsersFailed(state, action) {
      state.isFetchingUsers = false;
      state.isFetchingUsersSuccess = false;
      state.isFetchingUsersFailed = true;
    },
    logAsUser(state, action) {
      state.isLoggingAs = true;
      state.isLoggingAsSuccess = false;
      state.isLoggingAsFailed = false;
    },
    logAsUserSuccess(state, action) {
      state.isLoggingAs = false;
      state.isLoggingAsSuccess = true;
      state.isLoggingAsFailed = false;
      state.LogAsResponse = action.payload;
    },
    logAsUserFailed(state, action) {
      state.isLoggingAs = false;
      state.isLoggingAsSuccess = false;
      state.isLoggingAsFailed = true;
    },
    getUserProfile(state, action) {
      state.isGettingUserProfile = true;
      state.isGettingUserProfileSuccess = false;
      state.isGettingUserProfileFailed = false;
    },
    getUserProfileSuccess(state, action) {
      state.isGettingUserProfile = false;
      state.isGettingUserProfileSuccess = true;
      state.isGettingUserProfileFailed = false;
      state.UserProfileResponse = action.payload;
    },
    getUserProfileFailed(state, action) {
      state.isGettingUserProfile = false;
      state.isGettingUserProfileSuccess = false;
      state.isGettingUserProfileFailed = true;
    },
    editUserProfile(state, action) {
      state.isEditingUserProfile = true;
      state.isEditingUserProfileSuccess = false;
      state.isEditingUserProfileFailed = false;
    },
    editUserProfileSuccess(state, action) {
      state.isEditingUserProfile = false;
      state.isEditingUserProfileSuccess = true;
      state.isEditingUserProfileFailed = false;
      state.UserProfileResponse = action.payload;
    },
    editUserProfileFailed(state, action) {
      state.isEditingUserProfile = false;
      state.isEditingUserProfileSuccess = false;
      state.isEditingUserProfileFailed = true;
    },
    updateUserEmail(state, action) {
      state.isUpdatingUserEmail = true;
      state.isUpdatingUserEmailSuccess = false;
      state.isUpdatingUserEmailFailed = false;
    },
    updateUserEmailSuccess(state, action) {
      state.isUpdatingUserEmail = false;
      state.isUpdatingUserEmailSuccess = true;
      state.isUpdatingUserEmailFailed = false;
      state.UpdateUserEmailResponse = action.payload;
    },
    updateUserEmailFailed(state, action) {
      state.isUpdatingUserEmail = false;
      state.isUpdatingUserEmailSuccess = false;
      state.isUpdatingUserEmailFailed = true;
    },
  }
})

export const {
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFailed,
  getUsers,
  getUsersSuccess,
  getUsersFailed,
  logAsUser,
  logAsUserSuccess,
  logAsUserFailed,
  getUserProfile,
  getUserProfileSuccess,
  getUserProfileFailed,
  editUserProfile,
  editUserProfileSuccess,
  editUserProfileFailed,
  updateUserEmail,
  updateUserEmailSuccess,
  updateUserEmailFailed,
} = authSlice.actions;

export default authSlice.reducer;

export const selectAuthState = (state) => state.authState;
