import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFetchingStores: false,
  isFetchingStoresSuccess: false,
  isFetchingStoresFailed: false,
  UserStores: [],
  isFetchingStoreDetail: false,
  isFetchingStoreDetailSuccess: false,
  isFetchingStoreDetailFailed: false,
  StoreDetail: {},
  isUpdatingStore: false,
  isUpdatingStoreSuccess: false,
  isUpdatingStoreFailed: false,
  isFetchingStoreCollections: false,
  isFetchingStoreCollectionsSuccess: false,
  isFetchingStoreCollectionsFailed: false,
  StoreCollections: [],
  isCreatingStoreCollection: false,
  isCreatingStoreCollectionSuccess: false,
  isCreatingStoreCollectionFailed: false,
  CreateStoreCollectionResponse: {},
  isBulkDeletingStoreCollections: false,
  isBulkDeletingStoreCollectionsSuccess: false,
  isBulkDeletingStoreCollectionsFailed: false,
  bulkDeleteStoreCollectionsResponse: {},
  isBulkEditingCollectionProductTitles: false,
  isBulkEditingCollectionProductTitlesSuccess: false,
  isBulkEditingCollectionProductTitlesFailed: false,
  bulkEditCollectionProductTitlesResponse: {},
  isBulkEditingCollectionProductPrices: false,
  isBulkEditingCollectionProductPricesSuccess: false,
  isBulkEditingCollectionProductPricesFailed: false,
  bulkEditCollectionProductPricesResponse: {},
  isFetchingStoreCollectionsWithProducts: false,
  isFetchingStoreCollectionsWithProductsSuccess: false,
  isFetchingStoreCollectionsWithProductsFailed: false,
  StoreCollectionsWithProductsResponse: {},
  isFetchingStoreCollectionProducts: false,
  isFetchingStoreCollectionProductsSuccess: false,
  isFetchingStoreCollectionProductsFailed: false,
  StoreCollectionProducts: [],
  StoreCollectionCarousels: [],
  isUpdatingStoreCollection: false,
  isUpdatingStoreCollectionSuccess: false,
  isUpdatingStoreCollectionFailed: false,
  UpdateStoreCollectionResponse: {},
  isUpdatingStoreCollectionImage: false,
  isUpdatingStoreCollectionImageSuccess: false,
  isUpdatingStoreCollectionImageFailed: false,
  UpdateStoreCollectionImageResponse: {},
  isBulkUpdatingStoreProductsStatus: false,
  isBulkUpdatingStoreProductsStatusSuccess: false,
  isBulkUpdatingStoreProductsStatusFailed: false,
  isBulkUpdatingStoreProductsPrice: false,
  isBulkUpdatingStoreProductsPriceSuccess: false,
  isBulkUpdatingStoreProductsPriceFailed: false,
  isBulkDeletingStoreProducts: false,
  isBulkDeletingStoreProductsSuccess: false,
  isBulkDeletingStoreProductsFailed: false,
  isFetchingStoreProductDetails: false,
  isFetchingStoreProductDetailsSuccess: false,
  isFetchingStoreProductDetailsFailed: false,
  StoreProductDetails: {},
  isUpdatingStoreProductDetails: false,
  isUpdatingStoreProductDetailsSuccess: false,
  isUpdatingStoreProductDetailsFailed: false,
  isUpdatingStoreProductImage: false,
  isUpdatingStoreProductImageSuccess: false,
  isUpdatingStoreProductImageFailed: false,
  UpdateStoreProductImageResponse: {},
  isUndoingStoreProductImage: false,
  isUndoingStoreProductImageSuccess: false,
  isUndoingStoreProductImageFailed: false,
  UndoStoreProductImageResponse: {},
  isPostingStoreProducts: false,
  isPostingStoreProductsSuccess: false,
  isPostingStoreProductsFailed: false,
  productsToPublishStore: [],
  bulkEditOption: null,
  selectedUserStoreCollection: {},
  selectedUserStore: {},
};

const storesSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    getUserStores(state, action) {
      state.isFetchingStores = true;
      state.isFetchingStoresSuccess = false;
      state.isFetchingStoresFailed = false;
    },
    getUserStoresSuccess(state, action) {
      state.isFetchingStores = false;
      state.isFetchingStoresSuccess = true;
      state.isFetchingStoresFailed = false;
      state.UserStores = action.payload;
    },
    getUserStoresFailed(state, action) {
      state.isFetchingStores = false;
      state.isFetchingStoresSuccess = false;
      state.isFetchingStoresFailed = true;
    },
    getStoreDetail(state, action) {
      state.isFetchingStoreDetail = true;
      state.isFetchingStoreDetailSuccess = false;
      state.isFetchingStoreDetailFailed = false;
    },
    getStoreDetailSuccess(state, action) {
      state.isFetchingStoreDetail = false;
      state.isFetchingStoreDetailSuccess = true;
      state.isFetchingStoreDetailFailed = false;
      state.StoreDetail = action.payload;
    },
    getStoreDetailFailed(state, action) {
      state.isFetchingStoreDetail = false;
      state.isFetchingStoreDetailSuccess = false;
      state.isFetchingStoreDetailFailed = true;
    },
    updateStore(state, action) {
      state.isUpdatingStore = true;
      state.isUpdatingStoreSuccess = false;
      state.isUpdatingStoreFailed = false;
    },
    updateStoreSuccess(state, action) {
      state.isUpdatingStore = false;
      state.isUpdatingStoreSuccess = true;
      state.isUpdatingStoreFailed = false;
    },
    updateStoreFailed(state, action) {
      state.isUpdatingStore = false;
      state.isUpdatingStoreSuccess = false;
      state.isUpdatingStoreFailed = true;
    },
    getStoreCollections(state, action) {
      state.isFetchingStoreCollections = true;
      state.isFetchingStoreCollectionsSuccess = false;
      state.isFetchingStoreCollectionsFailed = false;
    },
    getStoreCollectionsSuccess(state, action) {
      state.isFetchingStoreCollections = false;
      state.isFetchingStoreCollectionsSuccess = true;
      state.isFetchingStoreCollectionsFailed = false;
      state.StoreCollections = action.payload;
    },
    getStoreCollectionsFailed(state, action) {
      state.isFetchingStoreCollections = false;
      state.isFetchingStoreCollectionsSuccess = false;
      state.isFetchingStoreCollectionsFailed = true;
    },
    createStoreCollection(state, action) {
      state.isCreatingStoreCollection = true;
      state.isCreatingStoreCollectionSuccess = false;
      state.isCreatingStoreCollectionFailed = false;
    },
    createStoreCollectionSuccess(state, action) {
      state.isCreatingStoreCollection = false;
      state.isCreatingStoreCollectionSuccess = true;
      state.isCreatingStoreCollectionFailed = false;
      state.CreateStoreCollectionResponse = action.payload;
    },
    createStoreCollectionFailed(state, action) {
      state.isCreatingStoreCollection = false;
      state.isCreatingStoreCollectionSuccess = false;
      state.isCreatingStoreCollectionFailed = true;
    },
    bulkDeleteStoreCollections(state, action) {
      state.isBulkDeletingStoreCollections = true;
      state.isBulkDeletingStoreCollectionsSuccess = false;
      state.isBulkDeletingStoreCollectionsFailed = false;
    },
    bulkDeleteStoreCollectionsSuccess(state, action) {
      state.isBulkDeletingStoreCollections = false;
      state.isBulkDeletingStoreCollectionsSuccess = true;
      state.isBulkDeletingStoreCollectionsFailed = false;
      state.bulkDeleteStoreCollectionsResponse = action.payload;
    },
    bulkDeleteStoreCollectionsFailed(state, action) {
      state.isBulkDeletingStoreCollections = false;
      state.isBulkDeletingStoreCollectionsSuccess = false;
      state.isBulkDeletingStoreCollectionsFailed = true;
    },
    bulkEditCollectionProductTitles(state, action) {
      state.isBulkEditingCollectionProductTitles = true;
      state.isBulkEditingCollectionProductTitlesSuccess = false;
      state.isBulkEditingCollectionProductTitlesFailed = false;
    },
    bulkEditCollectionProductTitlesSuccess(state, action) {
      state.isBulkEditingCollectionProductTitles = false;
      state.isBulkEditingCollectionProductTitlesSuccess = true;
      state.isBulkEditingCollectionProductTitlesFailed = false;
      state.bulkEditCollectionProductTitlesResponse = action.payload;
    },
    bulkEditCollectionProductTitlesFailed(state, action) {
      state.isBulkEditingCollectionProductTitles = false;
      state.isBulkEditingCollectionProductTitlesSuccess = false;
      state.isBulkEditingCollectionProductTitlesFailed = true;
    },
    bulkEditCollectionProductPrices(state, action) {
      state.isBulkEditingCollectionProductPrices = true;
      state.isBulkEditingCollectionProductPricesSuccess = false;
      state.isBulkEditingCollectionProductPricesFailed = false;
    },
    bulkEditCollectionProductPricesSuccess(state, action) {
      state.isBulkEditingCollectionProductPrices = false;
      state.isBulkEditingCollectionProductPricesSuccess = true;
      state.isBulkEditingCollectionProductPricesFailed = false;
      state.bulkEditCollectionProductPricesResponse = action.payload;
    },
    bulkEditCollectionProductPricesFailed(state, action) {
      state.isBulkEditingCollectionProductPrices = false;
      state.isBulkEditingCollectionProductPricesSuccess = false;
      state.isBulkEditingCollectionProductPricesFailed = true;
    },
    getStoreCollectionsWithProducts(state, action) {
      state.isFetchingStoreCollectionsWithProducts = true;
      state.isFetchingStoreCollectionsWithProductsSuccess = false;
      state.isFetchingStoreCollectionsWithProductsFailed = false;
    },
    getStoreCollectionsWithProductsSuccess(state, action) {
      state.isFetchingStoreCollectionsWithProducts = false;
      state.isFetchingStoreCollectionsWithProductsSuccess = true;
      state.isFetchingStoreCollectionsWithProductsFailed = false;
      state.StoreCollectionsWithProductsResponse = action.payload;
    },
    getStoreCollectionsWithProductsFailed(state, action) {
      state.isFetchingStoreCollectionsWithProducts = false;
      state.isFetchingStoreCollectionsWithProductsSuccess = false;
      state.isFetchingStoreCollectionsWithProductsFailed = true;
    },
    getStoreCollectionProducts(state, action) {
      state.isFetchingStoreCollectionProducts = true;
      state.isFetchingStoreCollectionProductsSuccess = false;
      state.isFetchingStoreCollectionProductsFailed = false;
    },
    getStoreCollectionProductsSuccess(state, action) {
      state.isFetchingStoreCollectionProducts = false;
      state.isFetchingStoreCollectionProductsSuccess = true;
      state.isFetchingStoreCollectionProductsFailed = false;
      state.StoreCollectionProducts = action.payload.products;
      state.StoreCollectionCarousels = action.payload.carousels;
    },
    getStoreCollectionProductsFailed(state, action) {
      state.isFetchingStoreCollectionProducts = false;
      state.isFetchingStoreCollectionProductsSuccess = false;
      state.isFetchingStoreCollectionProductsFailed = true;
    },
    updateStoreCollection(state, action) {
      state.isUpdatingStoreCollection = true;
      state.isUpdatingStoreCollectionSuccess = false;
      state.isUpdatingStoreCollectionFailed = false;
    },
    updateStoreCollectionSuccess(state, action) {
      state.isUpdatingStoreCollection = false;
      state.isUpdatingStoreCollectionSuccess = true;
      state.isUpdatingStoreCollectionFailed = false;
      state.UpdateStoreCollectionResponse = action.payload;
    },
    updateStoreCollectionFailed(state, action) {
      state.isUpdatingStoreCollection = false;
      state.isUpdatingStoreCollectionSuccess = false;
      state.isUpdatingStoreCollectionFailed = true;
    },
    updateStoreCollectionImage(state, action) {
      state.isUpdatingStoreCollectionImage = true;
      state.isUpdatingStoreCollectionImageSuccess = false;
      state.isUpdatingStoreCollectionImageFailed = false;
    },
    updateStoreCollectionImageSuccess(state, action) {
      state.isUpdatingStoreCollectionImage = false;
      state.isUpdatingStoreCollectionImageSuccess = true;
      state.isUpdatingStoreCollectionImageFailed = false;
      state.UpdateStoreCollectionImageResponse = action.payload;
    },
    updateStoreCollectionImageFailed(state, action) {
      state.isUpdatingStoreCollectionImage = false;
      state.isUpdatingStoreCollectionImageSuccess = false;
      state.isUpdatingStoreCollectionImageFailed = true;
    },
    bulkUpdateStoreProductsStatus(state, action) {
      state.isBulkUpdatingStoreProductsStatus = true;
      state.isBulkUpdatingStoreProductsStatusSuccess = false;
      state.isBulkUpdatingStoreProductsStatusFailed = false;
    },
    bulkUpdateStoreProductsStatusSuccess(state, action) {
      state.isBulkUpdatingStoreProductsStatus = false;
      state.isBulkUpdatingStoreProductsStatusSuccess = true;
      state.isBulkUpdatingStoreProductsStatusFailed = false;
    },
    bulkUpdateStoreProductsStatusFailed(state, action) {
      state.isBulkUpdatingStoreProductsStatus = false;
      state.isBulkUpdatingStoreProductsStatusSuccess = false;
      state.isBulkUpdatingStoreProductsStatusFailed = true;
    },
    bulkUpdateStoreProductsPrice(state, action) {
      state.isBulkUpdatingStoreProductsPrice = true;
      state.isBulkUpdatingStoreProductsPriceSuccess = false;
      state.isBulkUpdatingStoreProductsPriceFailed = false;
    },
    bulkUpdateStoreProductsPriceSuccess(state, action) {
      state.isBulkUpdatingStoreProductsPrice = false;
      state.isBulkUpdatingStoreProductsPriceSuccess = true;
      state.isBulkUpdatingStoreProductsPriceFailed = false;
    },
    bulkUpdateStoreProductsPriceFailed(state, action) {
      state.isBulkUpdatingStoreProductsPrice = false;
      state.isBulkUpdatingStoreProductsPriceSuccess = false;
      state.isBulkUpdatingStoreProductsPriceFailed = true;
    },
    bulkDeleteStoreProducts(state, action) {
      state.isBulkDeletingStoreProducts = true;
      state.isBulkDeletingStoreProductsSuccess = false;
      state.isBulkDeletingStoreProductsFailed = false;
    },
    bulkDeleteStoreProductsSuccess(state, action) {
      state.isBulkDeletingStoreProducts = false;
      state.isBulkDeletingStoreProductsSuccess = true;
      state.isBulkDeletingStoreProductsFailed = false;
    },
    bulkDeleteStoreProductsFailed(state, action) {
      state.isBulkDeletingStoreProducts = false;
      state.isBulkDeletingStoreProductsSuccess = false;
      state.isBulkDeletingStoreProductsFailed = true;
    },
    getStoreProductDetails(state, action) {
      state.isFetchingStoreProductDetails = true;
      state.isFetchingStoreProductDetailsSuccess = false;
      state.isFetchingStoreProductDetailsFailed = false;
    },
    getStoreProductDetailsSuccess(state, action) {
      state.isFetchingStoreProductDetails = false;
      state.isFetchingStoreProductDetailsSuccess = true;
      state.isFetchingStoreProductDetailsFailed = false;
      state.StoreProductDetails = action.payload;
    },
    getStoreProductDetailsFailed(state, action) {
      state.isFetchingStoreProductDetails = false;
      state.isFetchingStoreProductDetailsSuccess = false;
      state.isFetchingStoreProductDetailsFailed = true;
    },
    updateStoreProductDetails(state, action) {
      state.isUpdatingStoreProductDetails = true;
      state.isUpdatingStoreProductDetailsSuccess = false;
      state.isUpdatingStoreProductDetailsFailed = false;
    },
    updateStoreProductDetailsSuccess(state, action) {
      state.isUpdatingStoreProductDetails = false;
      state.isUpdatingStoreProductDetailsSuccess = true;
      state.isUpdatingStoreProductDetailsFailed = false;
      state.StoreProductDetails = action.payload;
    },
    updateStoreProductDetailsFailed(state, action) {
      state.isUpdatingStoreProductDetails = false;
      state.isUpdatingStoreProductDetailsSuccess = false;
      state.isUpdatingStoreProductDetailsFailed = true;
    },
    updateStoreProductImage(state, action) {
      state.isUpdatingStoreProductImage = true;
      state.isUpdatingStoreProductImageSuccess = false;
      state.isUpdatingStoreProductImageFailed = false;
    },
    updateStoreProductImageSuccess(state, action) {
      state.isUpdatingStoreProductImage = false;
      state.isUpdatingStoreProductImageSuccess = true;
      state.isUpdatingStoreProductImageFailed = false;
      state.UpdateStoreProductImageResponse = action.payload;
    },
    updateStoreProductImageFailed(state, action) {
      state.isUpdatingStoreProductImage = false;
      state.isUpdatingStoreProductImageSuccess = false;
      state.isUpdatingStoreProductImageFailed = true;
    },
    undoStoreProductImage(state, action) {
      state.isUndoingStoreProductImage = true;
      state.isUndoingStoreProductImageSuccess = false;
      state.isUndoingStoreProductImageFailed = false;
    },
    undoStoreProductImageSuccess(state, action) {
      state.isUndoingStoreProductImage = false;
      state.isUndoingStoreProductImageSuccess = true;
      state.isUndoingStoreProductImageFailed = false;
      state.UndoStoreProductImageResponse = action.payload;
    },
    undoStoreProductImageFailed(state, action) {
      state.isUndoingStoreProductImage = false;
      state.isUndoingStoreProductImageSuccess = false;
      state.isUndoingStoreProductImageFailed = true;
    },
    postStoreProducts(state, action) {
      state.isPostingStoreProducts = true;
      state.isPostingStoreProductsSuccess = false;
      state.isPostingStoreProductsFailed = false;
    },
    postStoreProductsSuccess(state, action) {
      state.isPostingStoreProducts = false;
      state.isPostingStoreProductsSuccess = true;
      state.isPostingStoreProductsFailed = false;
    },
    postStoreProductsFailed(state, action) {
      state.isPostingStoreProducts = false;
      state.isPostingStoreProductsSuccess = false;
      state.isPostingStoreProductsFailed = true;
    },
    setProductsToPublishStore(state, action) {
      state.productsToPublishStore = action.payload.productsToPublishStore;
      state.bulkEditOption = action.payload.bulkEditOption;
    },
    resetProductsToPublishStore(state) {
      state.productsToPublishStore = [];
      state.bulkEditOption = null;
    },
    setSelectedUserStore(state, action) {
      state.selectedUserStore = action.payload.selectedUserStore;
    },
    resetSelectedUserStore(state, action) {
      state.selectedUserStore = {};
    },
    setSelectedUserStoreCollection(state, action) {
      state.selectedUserStoreCollection = action.payload.selectedUserStoreCollection;
    },
    resetSelectedUserStoreCollection(state, action) {
      state.selectedUserStoreCollection = {};
    },
    getResetAllFlags(state) {
      return {
        ...initialState,
        UserStores: state.UserStores,
        StoreDetail: state.StoreDetail,
        StoreCollections: state.StoreCollections,
        CreateStoreCollectionResponse: state.CreateStoreCollectionResponse,
        bulkDeleteStoreCollectionsResponse: state.bulkDeleteStoreCollectionsResponse,
        StoreCollectionsWithProductsResponse: state.StoreCollectionsWithProductsResponse,
        StoreCollectionProducts: state.StoreCollectionProducts,
        StoreCollectionCarousels: state.StoreCollectionCarousels,
        UpdateStoreCollectionResponse: state.UpdateStoreCollectionResponse,
        StoreProductDetails: state.StoreProductDetails,
      }
    }
  }
})

export const {
  getUserStores,
  getUserStoresSuccess,
  getUserStoresFailed,
  getStoreDetail,
  getStoreDetailSuccess,
  getStoreDetailFailed,
  updateStore,
  updateStoreSuccess,
  updateStoreFailed,
  getStoreCollections,
  getStoreCollectionsSuccess,
  getStoreCollectionsFailed,
  createStoreCollection,
  createStoreCollectionSuccess,
  createStoreCollectionFailed,
  bulkDeleteStoreCollections,
  bulkDeleteStoreCollectionsSuccess,
  bulkDeleteStoreCollectionsFailed,
  bulkEditCollectionProductTitles,
  bulkEditCollectionProductTitlesSuccess,
  bulkEditCollectionProductTitlesFailed,
  bulkEditCollectionProductPrices,
  bulkEditCollectionProductPricesSuccess,
  bulkEditCollectionProductPricesFailed,
  getStoreCollectionsWithProducts,
  getStoreCollectionsWithProductsSuccess,
  getStoreCollectionsWithProductsFailed,
  getStoreCollectionProducts,
  getStoreCollectionProductsSuccess,
  getStoreCollectionProductsFailed,
  updateStoreCollection,
  updateStoreCollectionSuccess,
  updateStoreCollectionFailed,
  updateStoreCollectionImage,
  updateStoreCollectionImageSuccess,
  updateStoreCollectionImageFailed,
  bulkUpdateStoreProductsStatus,
  bulkUpdateStoreProductsStatusSuccess,
  bulkUpdateStoreProductsStatusFailed,
  bulkUpdateStoreProductsPrice,
  bulkUpdateStoreProductsPriceSuccess,
  bulkUpdateStoreProductsPriceFailed,
  bulkDeleteStoreProducts,
  bulkDeleteStoreProductsSuccess,
  bulkDeleteStoreProductsFailed,
  getStoreProductDetails,
  getStoreProductDetailsSuccess,
  getStoreProductDetailsFailed,
  updateStoreProductDetails,
  updateStoreProductDetailsSuccess,
  updateStoreProductDetailsFailed,
  updateStoreProductImage,
  updateStoreProductImageSuccess,
  updateStoreProductImageFailed,
  undoStoreProductImage,
  undoStoreProductImageSuccess,
  undoStoreProductImageFailed,
  postStoreProducts,
  postStoreProductsSuccess,
  postStoreProductsFailed,
  setProductsToPublishStore,
  resetProductsToPublishStore,
  setSelectedUserStore,
  resetSelectedUserStore,
  setSelectedUserStoreCollection,
  resetSelectedUserStoreCollection,
  getResetAllFlags,
} = storesSlice.actions;

export default storesSlice.reducer;

export const selectStoresState = (state) => state.storesState;
