import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import createSagaMiddleware from "redux-saga";
import storesReducer from './sagas/stores/storesSlice';
import { storesSaga } from './sagas/stores/storesSaga';
import checkoutReducer from './sagas/checkout/checkoutSlice';
import { checkoutSaga } from './sagas/checkout/checkoutSaga';
import downloadsReducer from './sagas/downloads/downloadsSlice';
import { downloadsSaga } from './sagas/downloads/downloadsSaga';
import ordersReducer from './sagas/orders/ordersSlice';
import { ordersSaga } from './sagas/orders/ordersSaga';
import paymentReducer from './sagas/payment/paymentSlice';
import { paymentSaga } from './sagas/payment/paymentSaga';
import creditsReducer from './sagas/credits/creditsSlice';
import { creditsSaga } from './sagas/credits/creditsSaga';
import authReducer from './sagas/auth/authSlice';
import { authSaga } from './sagas/auth/authSaga';
import carouselsReducer from './sagas/carousels/carouselsSlice';
import { carouselsSaga } from './sagas/carousels/carouselsSaga';
import imageReducer from './sagas/image/imageSlice';
import { imageSaga } from './sagas/image/imageSaga';

const combinedReducer = combineReducers({
  storesState: storesReducer,
  checkoutState: checkoutReducer,
  downloadsState: downloadsReducer,
  ordersState: ordersReducer,
  paymentState: paymentReducer,
  creditsState: creditsReducer,
  authState: authReducer,
  carouselsState: carouselsReducer,
  imageState: imageReducer,
});

const rootReducer = (state, action) => {
  return combinedReducer(state, action);
};

const sagaMiddleware = createSagaMiddleware({ context: { life: `bowl of something` } });

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: [],
        ignoredPaths: [],
      },
    }).concat(sagaMiddleware),
});

setupListeners(store.dispatch);

sagaMiddleware.run(storesSaga);
sagaMiddleware.run(checkoutSaga);
sagaMiddleware.run(downloadsSaga);
sagaMiddleware.run(ordersSaga);
sagaMiddleware.run(paymentSaga);
sagaMiddleware.run(creditsSaga);
sagaMiddleware.run(authSaga);
sagaMiddleware.run(carouselsSaga);
sagaMiddleware.run(imageSaga);
