
import { call, put, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import { getAuthorizationParam } from "../../services/apiCalls";
import {
  generateArts,
  generateArtsSuccess,
  generateArtsFailed,
  upscaleImage,
  upscaleImageSuccess,
  upscaleImageFailed,
  getSavedCarousels,
  getSavedCarouselsSuccess,
  getSavedCarouselsFailed,
  deleteCarousels,
  deleteCarouselsSuccess,
  deleteCarouselsFailed,
  getSavedCarouselsToDisplay,
  getSavedCarouselsToDisplaySuccess,
  getSavedCarouselsToDisplayFailed,
  saveCarousel,
  saveCarouselSuccess,
  saveCarouselFailed,
  updateCarousel,
  updateCarouselSuccess,
  updateCarouselFailed,
} from "./carouselsSlice";

const BASE_URL = process.env.GATSBY_BASE_URL;

export function requestGenerateArts(prompt, count) {
  return axios.get(`${BASE_URL}/api/v1/carousels/generate`, {
    params: {
      prompt,
      count,
    },
    ...getAuthorizationParam(),
  });
}

export function requestUpscaleImage(payload) {
  return axios.post(`${BASE_URL}/api/v1/carousels/upscale4x`, payload);
}

export function requestGetSavedCarousels(payload) {
  return axios.post(`${BASE_URL}/api/v1/carousels/get_saved`, payload, getAuthorizationParam());
}

export function requestDeleteCarousels(payload) {
  return axios.post(`${BASE_URL}/api/v1/carousels/remove_selected`, payload, getAuthorizationParam());
}

export function requestGetSavedCarouselsToDisplay(payload) {
  return axios.post(`${BASE_URL}/api/v1/carousels/get_savedItemstoDisplay`, payload, getAuthorizationParam());
}

export function requestSaveCarousel(payload) {
  return axios.post(`${BASE_URL}/api/v1/carousels/save`, payload, getAuthorizationParam());
}

export function requestUpdateCarousel(payload) {
  return axios.post(`${BASE_URL}/api/v1/carousels/update_sku`, payload, getAuthorizationParam());
}

export function* handleGenerateArts(action) {
  try {
    const response = yield call(requestGenerateArts, action.payload.prompt, action.payload.count);
    const { data } = response;

    if (data.status === "success") {
      yield put(generateArtsSuccess(data));
    } else {
      yield put(
        generateArtsFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      generateArtsFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleUpscaleImage(action) {
  try {
    const response = yield call(requestUpscaleImage, action.payload);
    const { data } = response;

    if (data.status === 'success') {
      yield put(upscaleImageSuccess(data));
    } else {
      yield put(
        upscaleImageFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      upscaleImageFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleGetSavedCarousels(action) {
  try {
    const response = yield call(requestGetSavedCarousels, action.payload);
    const { data } = response;

    if (data.code === 200) {
      yield put(getSavedCarouselsSuccess(data));
    } else {
      yield put(
        getSavedCarouselsFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      getSavedCarouselsFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleDeleteCarousels(action) {
  try {
    const response = yield call(requestDeleteCarousels, action.payload);
    const { data } = response;

    if (data.status === 'success') {
      yield put(deleteCarouselsSuccess(data));
    } else {
      yield put(
        deleteCarouselsFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      deleteCarouselsFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleGetSavedCarouselsToDisplay(action) {
  try {
    const response = yield call(requestGetSavedCarouselsToDisplay, action.payload);
    const { data } = response;

    if (data.status === "success") {
      yield put(getSavedCarouselsToDisplaySuccess(data.result));
    } else {
      yield put(
        getSavedCarouselsToDisplayFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      getSavedCarouselsToDisplayFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleSaveCarousel(action) {
  try {
    const response = yield call(requestSaveCarousel, action.payload);
    const { data } = response;

    if (data.status === "success") {
      yield put(saveCarouselSuccess(data.result));
    } else {
      yield put(
        saveCarouselFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      saveCarouselFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleUpdateCarousel(action) {
  try {
    const response = yield call(requestUpdateCarousel, action.payload);
    const { data } = response;

    if (data.status === "success") {
      yield put(updateCarouselSuccess(data.result));
    } else {
      yield put(
        updateCarouselFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }
    
    yield put(
      updateCarouselFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* carouselsSaga() {
  yield takeLatest(generateArts.type, handleGenerateArts);
  yield takeLatest(upscaleImage.type, handleUpscaleImage);
  yield takeLatest(getSavedCarousels.type, handleGetSavedCarousels);
  yield takeLatest(deleteCarousels.type, handleDeleteCarousels);
  yield takeLatest(getSavedCarouselsToDisplay.type, handleGetSavedCarouselsToDisplay);
  yield takeLatest(saveCarousel.type, handleSaveCarousel);
  yield takeLatest(updateCarousel.type, handleUpdateCarousel);
}
