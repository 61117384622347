import { navigate } from "gatsby";
import "isomorphic-fetch";
import axios from "axios";
import cookies from "universal-cookie";
import firebase from "firebase/app";
import "firebase/auth";
import firebaseConfig from "../services/firebase.config";
import jwtDecode from "jwt-decode";

const Cookies = new cookies();

!firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
const googleProvider = new firebase.auth.GoogleAuthProvider();

export const setUser = (user) => {
  typeof window !== "undefined" && window.localStorage.setItem("currentUser", JSON.stringify(user));
}

export const setAccessToken = (access_token) => {
  typeof window !== "undefined" && window.localStorage.setItem("access_token", access_token);
}

export const getUserFromAccessToken = () => {
  const access_token = typeof window !== "undefined" && window.localStorage.getItem("access_token");

  let returnValue = {};

  if (access_token) {
    const decodedJwt = jwtDecode(access_token);
    if (decodedJwt.exp && decodedJwt.exp > (new Date().getTime() / 1000)) {
      returnValue = decodedJwt;
    }
  }

  if (!returnValue) {
    typeof window !== "undefined" && window.localStorage.removeItem("access_token");
    typeof window !== "undefined" && window.localStorage.removeItem("currentUser");
    typeof window !== "undefined" && (window.location.href = window.location.origin);    
  }

  return returnValue;
}

export const getAccessToken = () => {
  const access_token = typeof window !== "undefined" && window.localStorage.getItem("access_token");

  let returnValue;

  if (access_token) {
    const decodedJwt = jwtDecode(access_token);
    if (decodedJwt.exp && decodedJwt.exp > (new Date().getTime() / 1000)) {
      returnValue = access_token;
    }
  }

  if (!returnValue) {
    typeof window !== "undefined" && window.localStorage.removeItem("access_token");
    typeof window !== "undefined" && window.localStorage.removeItem("currentUser");
    typeof window !== "undefined" && (window.location.href = window.location.origin);    
  }

  return returnValue;
}

export const handleGoogleSignIn = () => {
  firebase
    .auth()
    .signInWithPopup(googleProvider)
    .then((result) => {
      const user = result.user;
      setUser(user);
      navigate("/beta");
    })
    .catch((error) => {
    });
};

export const handleDiscordSignIn = async (code) => {
  try {
    // getting access token from discord
    const data = new URLSearchParams({
      client_id: process.env.DISCORD_CLIENT_ID,
      client_secret: process.env.DISCORD_CLIENT_SECRET,
      grant_type: "authorization_code",
      code: code.toString(),
      redirect_uri: process.env.DISCORD_REDIRECT_URI,
    });
    const response = await axios.post(
      `${process.env.DISCORD_API_URL}/oauth2/token`,
      data.toString(),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    Cookies.set("discord_access_token", response?.data?.access_token, {
      path: "/",
      maxAge: response?.data?.expires_in,
    });
    localStorage.setItem(
      "discord_refresh_token",
      JSON.stringify(response?.data?.refresh_token)
    );

    // getting user data from discord
    const userDetails = await axios.get(
      `${process.env.DISCORD_API_URL}/users/@me`,
      {
        headers: {
          Authorization: `Bearer ${cookies.get("discord_access_token")}`,
        },
      }
    );
    setUser(userDetails);
    navigate("/beta");
  } catch (error) {}
};

// Detect if the app is running in a browser.
export const isBrowser = () => typeof window !== "undefined";

// Get user data from localstorage
export const getUser = () =>
  isBrowser() && window.localStorage.getItem("currentUser")
    ? JSON.parse(window.localStorage.getItem("currentUser"))
    : "{}";

// Save user data to localstorage

// Return true if user is logged in. Otherwise false.
export const isLoggedIn = () => {
  const access_token = typeof window !== "undefined" && window.localStorage.getItem("access_token");

  let returnValue;

  if (access_token) {
    const decodedJwt = jwtDecode(access_token);
    if (decodedJwt.exp && decodedJwt.exp > (new Date().getTime() / 1000)) {
      returnValue = access_token;
    }
  }

  if (!returnValue) {
    typeof window !== "undefined" && window.localStorage.removeItem("access_token");
    typeof window !== "undefined" && window.localStorage.removeItem("currentUser");
  }

  const user = getUser();
  return !!user.status && !!user.email && !!returnValue;
};

export const logout = (callback) => {
  setUser({});
  // navigate("/signin");
  navigate("/beta");
  Cookies.remove("discord_access_token", { path: "/" });
  localStorage.clear();

  // callback();
};

export const profiles = (e) => {
  e.preventDefault();
  navigate("/profile");
}

export const validateEmail = (email) => {
  var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  return emailReg.test(email);
};
