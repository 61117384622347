exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advance-jsx": () => import("./../../../src/pages/advance.jsx" /* webpackChunkName: "component---src-pages-advance-jsx" */),
  "component---src-pages-advanced-tab-jsx": () => import("./../../../src/pages/advanced-tab.jsx" /* webpackChunkName: "component---src-pages-advanced-tab-jsx" */),
  "component---src-pages-all-over-print-backpack-jsx": () => import("./../../../src/pages/all-over-print-backpack.jsx" /* webpackChunkName: "component---src-pages-all-over-print-backpack-jsx" */),
  "component---src-pages-basel-jsx": () => import("./../../../src/pages/basel.jsx" /* webpackChunkName: "component---src-pages-basel-jsx" */),
  "component---src-pages-beta-jsx": () => import("./../../../src/pages/beta.jsx" /* webpackChunkName: "component---src-pages-beta-jsx" */),
  "component---src-pages-cart-listing-jsx": () => import("./../../../src/pages/cart-listing.jsx" /* webpackChunkName: "component---src-pages-cart-listing-jsx" */),
  "component---src-pages-choose-art-jsx": () => import("./../../../src/pages/choose-art.jsx" /* webpackChunkName: "component---src-pages-choose-art-jsx" */),
  "component---src-pages-compare-art-jsx": () => import("./../../../src/pages/compare-art.jsx" /* webpackChunkName: "component---src-pages-compare-art-jsx" */),
  "component---src-pages-content-policy-jsx": () => import("./../../../src/pages/content-policy.jsx" /* webpackChunkName: "component---src-pages-content-policy-jsx" */),
  "component---src-pages-cookie-policy-jsx": () => import("./../../../src/pages/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-create-account-jsx": () => import("./../../../src/pages/create-account.jsx" /* webpackChunkName: "component---src-pages-create-account-jsx" */),
  "component---src-pages-create-password-jsx": () => import("./../../../src/pages/create-password.jsx" /* webpackChunkName: "component---src-pages-create-password-jsx" */),
  "component---src-pages-downloads-jsx": () => import("./../../../src/pages/downloads.jsx" /* webpackChunkName: "component---src-pages-downloads-jsx" */),
  "component---src-pages-help-jsx": () => import("./../../../src/pages/help.jsx" /* webpackChunkName: "component---src-pages-help-jsx" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/Home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-img-prompt-jsx": () => import("./../../../src/pages/img-prompt.jsx" /* webpackChunkName: "component---src-pages-img-prompt-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logo-cropper-jsx": () => import("./../../../src/pages/logo-cropper.jsx" /* webpackChunkName: "component---src-pages-logo-cropper-jsx" */),
  "component---src-pages-mockup-jsx": () => import("./../../../src/pages/mockup.jsx" /* webpackChunkName: "component---src-pages-mockup-jsx" */),
  "component---src-pages-my-creations-jsx": () => import("./../../../src/pages/my-creations.jsx" /* webpackChunkName: "component---src-pages-my-creations-jsx" */),
  "component---src-pages-my-creations-view-jsx": () => import("./../../../src/pages/my-creations-view.jsx" /* webpackChunkName: "component---src-pages-my-creations-view-jsx" */),
  "component---src-pages-my-storefronts-jsx": () => import("./../../../src/pages/my-storefronts.jsx" /* webpackChunkName: "component---src-pages-my-storefronts-jsx" */),
  "component---src-pages-order-summary-jsx": () => import("./../../../src/pages/order-summary.jsx" /* webpackChunkName: "component---src-pages-order-summary-jsx" */),
  "component---src-pages-otp-verification-jsx": () => import("./../../../src/pages/otp-verification.jsx" /* webpackChunkName: "component---src-pages-otp-verification-jsx" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-password-reset-jsx": () => import("./../../../src/pages/password-reset.jsx" /* webpackChunkName: "component---src-pages-password-reset-jsx" */),
  "component---src-pages-payment-cart-jsx": () => import("./../../../src/pages/payment-cart.jsx" /* webpackChunkName: "component---src-pages-payment-cart-jsx" */),
  "component---src-pages-preview-jsx": () => import("./../../../src/pages/preview.jsx" /* webpackChunkName: "component---src-pages-preview-jsx" */),
  "component---src-pages-pricing-options-jsx": () => import("./../../../src/pages/pricing-options.jsx" /* webpackChunkName: "component---src-pages-pricing-options-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-profile-jsx": () => import("./../../../src/pages/profile.jsx" /* webpackChunkName: "component---src-pages-profile-jsx" */),
  "component---src-pages-progress-bar-jsx": () => import("./../../../src/pages/progress-bar.jsx" /* webpackChunkName: "component---src-pages-progress-bar-jsx" */),
  "component---src-pages-publishing-store-jsx": () => import("./../../../src/pages/publishing-store.jsx" /* webpackChunkName: "component---src-pages-publishing-store-jsx" */),
  "component---src-pages-publishing-store-success-jsx": () => import("./../../../src/pages/publishing-store-success.jsx" /* webpackChunkName: "component---src-pages-publishing-store-success-jsx" */),
  "component---src-pages-quick-jsx": () => import("./../../../src/pages/quick.jsx" /* webpackChunkName: "component---src-pages-quick-jsx" */),
  "component---src-pages-remix-prompt-jsx": () => import("./../../../src/pages/remix-prompt.jsx" /* webpackChunkName: "component---src-pages-remix-prompt-jsx" */),
  "component---src-pages-review-place-order-jsx": () => import("./../../../src/pages/review-place-order.jsx" /* webpackChunkName: "component---src-pages-review-place-order-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-select-merch-jsx": () => import("./../../../src/pages/select-merch.jsx" /* webpackChunkName: "component---src-pages-select-merch-jsx" */),
  "component---src-pages-service-terms-jsx": () => import("./../../../src/pages/service-terms.jsx" /* webpackChunkName: "component---src-pages-service-terms-jsx" */),
  "component---src-pages-sharing-publication-policy-jsx": () => import("./../../../src/pages/sharing-publication-policy.jsx" /* webpackChunkName: "component---src-pages-sharing-publication-policy-jsx" */),
  "component---src-pages-shipping-address-jsx": () => import("./../../../src/pages/shipping-address.jsx" /* webpackChunkName: "component---src-pages-shipping-address-jsx" */),
  "component---src-pages-signin-jsx": () => import("./../../../src/pages/signin.jsx" /* webpackChunkName: "component---src-pages-signin-jsx" */),
  "component---src-pages-terms-of-use-jsx": () => import("./../../../src/pages/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-pages-usage-policy-jsx": () => import("./../../../src/pages/usage-policy.jsx" /* webpackChunkName: "component---src-pages-usage-policy-jsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

