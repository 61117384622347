import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFetchingOrderHistory: false,
  isFetchingOrderHistorySuccess: false,
  isFetchingOrderHistoryFailed: false,
  OrderHistoryData: [],
  isGettingOrderDetail: false,
  isGettingOrderDetailSuccess: false,
  isGettingOrderDetailFailed: false,
  OrderDetailData: {},
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    getOrderHistory(state, action) {
      state.isFetchingOrderHistory = true;
      state.isFetchingOrderHistorySuccess = false;
      state.isFetchingOrderHistoryFailed = false;
    },
    getOrderHistorySuccess(state, action) {
      state.isFetchingOrderHistory = false;
      state.isFetchingOrderHistorySuccess = true;
      state.isFetchingOrderHistoryFailed = false;
      state.OrderHistoryData = action.payload;
    },
    getOrderHistoryFailed(state, action) {
      state.isFetchingOrderHistory = false;
      state.isFetchingOrderHistorySuccess = false;
      state.isFetchingOrderHistoryFailed = true;
    },
    getOrderDetail(state, action) {
      state.isGettingOrderDetail = true;
      state.isGettingOrderDetailSuccess = false;
      state.isGettingOrderDetailFailed = false;
    },
    getOrderDetailSuccess(state, action) {
      state.isGettingOrderDetail = false;
      state.isGettingOrderDetailSuccess = true;
      state.isGettingOrderDetailFailed = false;
      state.OrderDetailData = action.payload;
    },
    getOrderDetailFailed(state, action) {
      state.isGettingOrderDetail = false;
      state.isGettingOrderDetailSuccess = false;
      state.isGettingOrderDetailFailed = true;
    },
  }
})

export const {
  getOrderHistory,
  getOrderHistorySuccess,
  getOrderHistoryFailed,
  getOrderDetail,
  getOrderDetailSuccess,
  getOrderDetailFailed,
} = ordersSlice.actions;

export default ordersSlice.reducer;

export const selectOrdersState = (state) => state.ordersState;
