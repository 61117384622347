
import { call, put, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import { getAuthorizationParam } from "../../services/apiCalls";
import {
  updatePaymentIntent,
  updatePaymentIntentSuccess,
  updatePaymentIntentFailed,
  postLedgerHistory,
  postLedgerHistorySuccess,
  postLedgerHistoryFailed,
  getLedgerHistory,
  getLedgerHistorySuccess,
  getLedgerHistoryFailed,
  getLedgerDetails,
  getLedgerDetailsSuccess,
  getLedgerDetailsFailed,
  getCreditHistory,
  getCreditHistorySuccess,
  getCreditHistoryFailed,
  getCreditDetails,
  getCreditDetailsSuccess,
  getCreditDetailsFailed,
} from "./creditsSlice";

const BASE_URL = process.env.GATSBY_BASE_URL;

export function requestUpdatePaymentIntent(payload) {
  return axios.post(`${BASE_URL}/api/v1/credits/update-payment-intent`, payload, getAuthorizationParam());
}

export function requestPostLedgerHistory(payload) {
  return axios.post(`${BASE_URL}/api/v1/credits/userCreditLedgerHistory`, payload, getAuthorizationParam());
}


export function requestGetLedgerHistory(perPage, page) {
  return axios
    .get(`${BASE_URL}/api/v1/credits/userCreditLedgerHistory`, {
      params: {
        perPage,
        page,
      },
      headers: getAuthorizationParam().headers,
    });
}

export function requestGetLedgerDetails(id) {
  return axios
    .get(`${BASE_URL}/api/v1/credits/userCretidLedgerHistoryDetails`, {
      params: {
        id,
      },
      headers: getAuthorizationParam().headers,
    });
}

export function requestGetCreditHistory(perPage, page) {
  return axios
    .get(`${BASE_URL}/api/v1/credits/userCreditHistoryList`, {
      params: {
        perPage,
        page,
      },
      headers: getAuthorizationParam().headers,
    });
}

export function requestGetCreditDetails(id) {
  return axios
    .get(`${BASE_URL}/api/v1/credits/userCreditHistoryGetDetails`, {
      params: {
        id,
      },
      headers: getAuthorizationParam().headers,
    });
}

export function* handleUpdatePaymentIntent(action) {
  try {
    const response = yield call(requestUpdatePaymentIntent, action.payload);
    const { data } = response;

    if (data.status === 'success') {
      yield put(updatePaymentIntentSuccess(data));
    } else {
      yield put(
        updatePaymentIntentFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      updatePaymentIntentFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handlePostLedgerHistory(action) {
  try {
    const response = yield call(requestPostLedgerHistory, action.payload);
    const { data } = response;

    if (data.code === 200) {
      yield put(postLedgerHistorySuccess(data));
    } else {
      yield put(
        postLedgerHistoryFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      postLedgerHistoryFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleGetLedgerHistory(action) {
  try {
    const response = yield call(requestGetLedgerHistory, action.payload.perPage, action.payload.page);
    const { data } = response;

    if (data.code === 200) {
      yield put(getLedgerHistorySuccess(data));
    } else {
      yield put(
        getLedgerHistoryFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      getLedgerHistoryFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleGetLedgerDetails(action) {
  try {
    const response = yield call(requestGetLedgerDetails, action.payload.id);
    const { data } = response;

    if (data.code === 200) {
      yield put(getLedgerDetailsSuccess(data.data));
    } else {
      yield put(
        getLedgerDetailsFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      getLedgerDetailsFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleGetCreditHistory(action) {
  try {
    const response = yield call(requestGetCreditHistory, action.payload.perPage, action.payload.page);
    const { data } = response;

    if (data.code === 200) {
      yield put(getCreditHistorySuccess(data));
    } else {
      yield put(
        getCreditHistoryFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }

    yield put(
      getCreditHistoryFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* handleGetCreditDetails(action) {
  try {
    const response = yield call(requestGetCreditDetails, action.payload.id);
    const { data } = response;

    if (data.code === 200) {
      yield put(getCreditDetailsSuccess(data.data));
    } else {
      yield put(
        getCreditDetailsFailed({
          message: 'Something went wrong',
          statusCode: data.code,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.code === "VALIDATION_FAILED") {
      window.localStorage.removeItem("access_token");
      window.location.href = `${window.location.origin}/signin`;
    }
    
    yield put(
      getCreditDetailsFailed({
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      })
    );
  }
}

export function* creditsSaga() {
  yield takeLatest(updatePaymentIntent.type, handleUpdatePaymentIntent);
  yield takeLatest(postLedgerHistory.type, handlePostLedgerHistory);
  yield takeLatest(getLedgerHistory.type, handleGetLedgerHistory);
  yield takeLatest(getLedgerDetails.type, handleGetLedgerDetails);
  yield takeLatest(getCreditHistory.type, handleGetCreditHistory);
  yield takeLatest(getCreditDetails.type, handleGetCreditDetails);
}
