import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPostingImage: false,
  isPostingImageSuccess: false,
  isPostingImageFailed: false,
  PostImageResponse: {},
};

const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    postImage(state, action) {
      state.isPostingImage = true;
      state.isPostingImageSuccess = false;
      state.isPostingImageFailed = false;
    },
    postImageSuccess(state, action) {
      state.isPostingImage = false;
      state.isPostingImageSuccess = true;
      state.isPostingImageFailed = false;
      state.PostImageResponse = action.payload;
    },
    postImageFailed(state, action) {
      state.isPostingImage = false;
      state.isPostingImageSuccess = false;
      state.isPostingImageFailed = true;
    },
  }
})

export const {
  postImage,
  postImageSuccess,
  postImageFailed,
} = imageSlice.actions;

export default imageSlice.reducer;

export const selectImageState = (state) => state.imageState;
